<!-- /* ALTERADO PELA PGE-RS:
a) Deixando o login automático: se tem números, é CPF; se não é pela rede.
b) Ajuste de labels.
*/ -->
<h1 mat-dialog-title>Sessão expirada</h1>
<div class="login-form" [@animate]="{value:'*', params:{duration:'300ms',y:'100px'}}"
     *ngIf="!loadingConfig && config && !config.error">
    <div class="logo" *ngIf="false">
        <img [src]="config.logo">
    </div>

    <div class="title mb-2">{{config.name | uppercase}}</div>

    <div class="subtitle mb-2">{{config.environment | uppercase}}</div>

    <br />
    <form name="form" [formGroup]="form" novalidate>

        <mat-form-field appearance="outline">
            <mat-label>{{form.get('tipoLogin').value === 'ldap' ? 'Usuário da Rede (ou CPF)' : 'CPF (ou usuário da rede)'}}</mat-label>
            <input matInput ui-mask="999.999.999-99" formControlName="username" (keyup)="onChangeTipoLogin2()" style="height: 22px;">
            <mat-icon matSuffix class="text-secondary">person</mat-icon>
            <mat-error *ngIf="form.get('username').hasError('required')">
                {{form.get('tipoLogin').value === 'ldap' ? 'Usuário da Rede' : 'CPF'}} é obrigatório
            </mat-error>
            <mat-error
                *ngIf="!form.get('username').hasError('required') &&
                                form.get('username').hasError('username')">
                Entre com um {{form.get('tipoLogin').value === 'ldap' ? 'Usuário da Rede' : 'CPF'}} válido
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Senha {{form.get('tipoLogin').value === 'ldap' ? 'da Rede' : 'do CPF'}}</mat-label>
            <input matInput type="password" formControlName="password" style="height: 22px;">
            <mat-icon matSuffix class="text-secondary">vpn_key</mat-icon>
            <mat-error>
                Senha é obrigatório
            </mat-error>
        </mat-form-field>

        <div *ngIf="errorMessage" class="flex flex-row xs:flex-col self-center">
            <mat-error>
                {{errorMessage}}
            </mat-error>
        </div>

        <button *ngIf="config.tiposLogin.includes('login_ldap_ativo') || config.tiposLogin.includes('login_interno_ativo')" (click)="onSubmit(form.value)" mat-raised-button color="primary" class="submit-button"
                aria-label="login"
                [disabled]="form.invalid">
            Acessar o SUPP
        </button>

        <a *ngIf="config.tiposLogin.includes('login_govbr_ativo') && config.govBR?.client_id" mat-raised-button
           [href]="config.govBR?.sso_url+'/authorize?response_type=code&client_id='+config.govBR?.client_id+'&scope=openid+email+phone+profile+govbr_confiabilidades&redirect_uri='+config.govBR?.redirect_uri"
           class="submit-button gov-br" aria-label="entrar com gov.br">
            Entrar com <strong>gov.br</strong>
        </a>

        <div class="flex flex-row xs:flex-col self-center">
            <mat-progress-bar [mode]="'indeterminate'" *ngIf="loading"></mat-progress-bar>
        </div>
    </form>

    <div class="subtitle mt-3">{{config.version}}</div>
</div>
