<div class="navbar-top">

    <div class="logo">
        <img class="logo-icon" [src]="_cdkConfigService.icone">
        <!-- <span class="logo-text text-secondary">PGE-RS</span> ALTERADO PELA PGE-RS: comentado! -->
    </div>

    <div class="buttons">

        <button mat-icon-button class="toggle-sidebar-folded lt-lg:hidden"
                (click)="toggleSidebarFolded()">
            <mat-icon class="text-secondary">menu</mat-icon>
        </button>

        <button mat-icon-button class="toggle-sidebar-opened lg:hidden"
                (click)="toggleSidebarOpened()">
            <mat-icon class="text-secondary">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div class="navbar-scroll-container"
     cdkScrollbar [cdkScrollbarOptions]="{wheelPropagation: false ,suppressScrollX: false}">

    <!-- ALTERADO PELA PGE-RS: comentado.
    <div class="user flex flex-col">

        <div class="h3 username">{{userProfile?.nome.split(' ')[0]}}</div>
        <div class="h5 email hint-text mt-2">{{userProfile?.email}}</div>
        <div class="avatar-container">
            <img class="avatar" src="assets/images/avatars/User.png"
                 *ngIf="!!userProfile?.imgPerfil?.conteudo === false">
            <img class="avatar" [src]="userProfile.imgPerfil.conteudo|safeResourceUrl"
                 *ngIf="!!userProfile?.imgPerfil?.conteudo === true">
        </div>

    </div>
    -->

    <div class="navbar-content_"> <!-- ALTERADO PELA PGE-RS: _ no final do css, para reduzir o espaço. -->
        <cdk-navigation class="material2" layout="vertical"></cdk-navigation>
    </div>

</div>
