import {AbstractComparisonOperator} from '@cdk/components/criteria/operators/abstract-comparison-operator';
import {ExpressionPositionEnum} from '@cdk/components/criteria/operators/index';

export default class NotContains extends AbstractComparisonOperator
{
    public readonly expression: string = 'notLike:%arg%';

    constructor(
        literalOperator: string = 'não contém',
        expressionPosition: ExpressionPositionEnum = ExpressionPositionEnum.MIDDLE
    ) {
        super(
            literalOperator,
            expressionPosition
        );
    }

    /**
     * Check if the expression pattern correspond to the operator.
     * @param expression
     */
    public checkExpression(expression: string): boolean {
        const regex: RegExp = new RegExp(/^notLike:(?:%(?!%)(?:[^%])*%)*$/);
        return regex.test(expression);
    }

    /**
     * Returns expression value.
     * @param expression
     */
    public getValueFromExpression(expression: string): string|null {
        const regex: RegExp = new RegExp(/^notLike:%(.*)%$/);
        const result = regex.exec(expression);
        return result ? result[1] : null;
    }
}
