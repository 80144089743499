<h1 mat-dialog-title>Informe a chave de acesso</h1>
<form [formGroup]="form">
    <div mat-dialog-content>
        <mat-form-field class="flex basis-[100%]">
            <mat-label>Chave de acesso</mat-label>
                <input matInput placeholder2="Chave acesso"
                       formControlName="chaveAcesso"
                       required>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Cancelar</button>
        <button mat-button [disabled]="form.invalid" [mat-dialog-close]="form.get('chaveAcesso').value">Confirmar</button>
    </div>
</form>
