<form name="form" [formGroup]="form" *ngIf="activeCard === 'form'">
    <div class="cdk-search-bar" [ngClass]="{'expanded':!collapsed}">
        <div class="cdk-search-bar-content">
            <button mat-icon-button class="cdk-search-bar-expander" aria-label="Expandir Barra de Busca"
                    (click)="expand()"
                    *ngIf="collapsed">
                <mat-icon class="s-24 text-secondary">search</mat-icon>
            </button>

            <div class="form-field-flex" *ngIf="!collapsed">
                <button mat-icon-button
                        class="custom-search-button"
                        aria-label="Pesquisar por"
                        [matTooltip]="'Pesquisando processos por ' + this.searchFieldName"
                        [matMenuTriggerFor]="menuBusca">
                    <mat-icon svgIcon="mat_outline:manage_search"></mat-icon>
                </button>
                <!-- /* ALTERADO PELA PGE-RS: removido o termo 'rápida de processos '. */ -->
                <input matInput class="input-field flex grow"
                       placeholder="Pesquisa por {{searchFieldName}}"
                       formControlName="processo"
                       [matAutocomplete]="processoSearch.autocomplete"
                       (blur)="checkProcesso()">
                <cdk-processo-search-autocomplete
                    #processoSearch="processoSearchAutocomplete"
                    [searchField]="searchField"
                    [control]="form.get('processo')"
                    [pagination]="processoPagination">
                </cdk-processo-search-autocomplete>
            </div>

            <button mat-icon-button *ngIf="!collapsed"
                    (click)="collapse()"
                    aria-label="Esconder Barra de Busca">
                <mat-icon class="s-24 text-secondary mr-4">close</mat-icon>
            </button>
        </div>
    </div>
    <mat-menu #menuBusca="matMenu" class="menu-busca" [overlapTrigger]="false" xPosition="before">
        <!-- /* ALTERADO PELA PGE-RS: ajustes visuais. */ -->
        <div class="m-1">
            <div class="custom-search-header">
                <span>Buscar processos por:</span>
            </div>
            <br />
            <div class="campo" (click)="selecionaCampo('NUP', 'NUP')" [ngClass]="{'selected': searchField === 'NUP'}">
                <span>NUP</span>
                <mat-icon class="text-base text-primary w-[14px] h-[14px] min-w-[14px] min-h-[14px]"
                          *ngIf="searchField === 'NUP'">check
                </mat-icon>
            </div>
            <div class="campo" (click)="selecionaCampo('outroNumero', 'Outro Número')"
                 [ngClass]="{'selected': searchField === 'outroNumero'}">
                <span>Outro Número</span>
                <mat-icon class="text-base text-primary w-[14px] h-[14px] min-w-[14px] min-h-[14px]"
                          *ngIf="searchField === 'outroNumero'">check
                </mat-icon>
            </div>

            <ng-template #dynamicComponent></ng-template>
        </div>
    </mat-menu>
</form>
