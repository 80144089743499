<div class="theme-options-panel" cdkScrollbar  [cdkScrollbarOptions]="{suppressScrollX: false, suppressScrollY: false, wheelPropagation: false, updateOnRouteChange: true}">

    <div class="header">

        <span class="title">Aparência</span>

        <button mat-icon-button class="close-button" (click)="toggleSidebarOpen('themeOptionsPanel')">
            <mat-icon>close</mat-icon>
        </button>

    </div>

    <form [formGroup]="form">

        <!-- COLOR THEME -->
        <div class="group">

            <h2>Tema</h2>

            <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3 font-weight-900" formControlName="colorTheme">
                <mat-radio-button color="primary" class="text-on-primary-900" *ngFor="let theme of this.cdkConfig.themes" [value]="theme.id">{{theme.name}}</mat-radio-button>
            </mat-radio-group>

        </div>

        <!-- COLOR SCHEME -->
        <div class="group">

            <h2>Modo</h2>

            <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="scheme">
                <mat-radio-button color="primary" value="auto">Auto</mat-radio-button>
                <mat-radio-button color="primary" value="light">Claro</mat-radio-button>
                <mat-radio-button color="primary" value="dark">Escuro</mat-radio-button>
            </mat-radio-group>

        </div>

        <!-- LAYOUT STYLES -->
        <div class="group hidden" formGroupName="layout">

            <h2>Estilo de Layout</h2>

            <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="style">

                <mat-radio-button color="primary" value="vertical-layout-1">
                    Vertical 1
                </mat-radio-button>

                <mat-radio-button color="primary" value="vertical-layout-2">
                    Vertical 2
                </mat-radio-button>

                <mat-radio-button color="primary" value="vertical-layout-3">
                    Vertical 3
                </mat-radio-button>

                <mat-radio-button color="primary" value="horizontal-layout-1">
                    Horizontal 1
                </mat-radio-button>

            </mat-radio-group>

            <!-- DIFFERENT FORMS BASED ON LAYOUT STYLES -->
            <ng-container [ngSwitch]="cdkConfig.layout.style">

                <!-- VERTICAL LAYOUT #1 -->
                <ng-container *ngSwitchCase="'vertical-layout-1'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-2">

                        <h2>Largura</h2>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="width">
                            <mat-radio-button color="primary" value="fullwidth">Tela Cheia</mat-radio-button>
                            <mat-radio-button color="primary" value="boxed">Caixa</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>Menu Lateral</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <mat-slide-toggle color="primary" class="mt-6" formControlName="folded">
                            Encolhido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="position">
                            <mat-radio-button color="primary" value="left">Esquerda</mat-radio-button>
                            <mat-radio-button color="primary" value="right">Direita</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-2">Variante:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="variant">
                            <mat-radio-button color="primary" value="vertical-style-1">Estilo 1</mat-radio-button>
                            <mat-radio-button color="primary" value="vertical-style-2">Estilo 2</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="my-2">Cor de Fundo Primária:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="primaryBackground">
                        </cdk-material-color-picker>

                        <h3 class="my-2">Cor de Fundo Secundária:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="secondaryBackground">
                        </cdk-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>Menu Superior</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above">Acima</mat-radio-button>
                            <mat-radio-button color="primary" value="below-static">Abaixo Estático</mat-radio-button>
                            <mat-radio-button color="primary" value="below-fixed">Abaixo Fixo</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor de Fundo:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>Rodapé</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above">Acima</mat-radio-button>
                            <mat-radio-button color="primary" value="below-static">Abaixo Estático</mat-radio-button>
                            <mat-radio-button color="primary" value="below-fixed">Abaixo Fixo</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>Painel Lateral</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="left">Esquerda</mat-radio-button>
                            <mat-radio-button color="primary" value="right">Direita</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- VERTICAL LAYOUT #2 -->
                <ng-container *ngSwitchCase="'vertical-layout-2'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-2">

                        <h2>Largura</h2>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="width">
                            <mat-radio-button color="primary" value="fullwidth">Tela Cheia</mat-radio-button>
                            <mat-radio-button color="primary" value="boxed">Caixa</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>Menu Lateral</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <mat-slide-toggle color="primary" class="mt-6" formControlName="folded">
                            Encolhido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="position">
                            <mat-radio-button color="primary" value="left">Esquerda</mat-radio-button>
                            <mat-radio-button color="primary" value="right">Direita</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-2">Variante:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="variant">
                            <mat-radio-button color="primary" value="vertical-style-1">Estilo 1</mat-radio-button>
                            <mat-radio-button color="primary" value="vertical-style-2">Estilo 2</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="my-2">Cor de Fundo Primária:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="primaryBackground">
                        </cdk-material-color-picker>

                        <h3 class="my-2">Cor de Fundo Secundária:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="secondaryBackground">
                        </cdk-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>Menu Superior</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above-static">Acima Estático</mat-radio-button>
                            <mat-radio-button color="primary" value="above-fixed">Acima Fixo</mat-radio-button>
                            <mat-radio-button color="primary" value="below">Abaixo</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor de Fundo:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>Rodapé</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above-static">Acima Estático</mat-radio-button>
                            <mat-radio-button color="primary" value="above-fixed">Acima Fixo</mat-radio-button>
                            <mat-radio-button color="primary" value="below">Abaixo</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor de Fundo:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>Painel Lateral</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="left">Esquerda</mat-radio-button>
                            <mat-radio-button color="primary" value="right">Direita</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- VERTICAL LAYOUT #3 -->
                <ng-container *ngSwitchCase="'vertical-layout-3'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-2">

                        <h2>Largura</h2>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="width">
                            <mat-radio-button color="primary" value="fullwidth">Tela Cheia</mat-radio-button>
                            <mat-radio-button color="primary" value="boxed">Caixa</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>Menu Lateral</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <mat-slide-toggle color="primary" class="mt-6" formControlName="folded">
                            Encolhido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="position">
                            <mat-radio-button color="primary" value="left">Esquerda</mat-radio-button>
                            <mat-radio-button color="primary" value="right">Direita</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-2">Variante:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="variant">
                            <mat-radio-button color="primary" value="vertical-style-1">Estilo 1</mat-radio-button>
                            <mat-radio-button color="primary" value="vertical-style-2">Estilo 2</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="my-2">Cor de Fundo Primária:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="primaryBackground">
                        </cdk-material-color-picker>

                        <h3 class="my-2">Cor de Fundo Secundária:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="secondaryBackground">
                        </cdk-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>Menu Superior</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above-static">Acima Estático</mat-radio-button>
                            <mat-radio-button color="primary" value="above-fixed">Acima Fixo</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor de Fundo:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>Rodapé</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above-static">Acima Estático</mat-radio-button>
                            <mat-radio-button color="primary" value="above-fixed">Acima Fixo</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor de Fundo:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>Side Painel</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="left">Esquerda</mat-radio-button>
                            <mat-radio-button color="primary" value="right">Direita</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

                <!-- HORIZONTAL LAYOUT #1 -->
                <ng-container *ngSwitchCase="'horizontal-layout-1'">

                    <!-- LAYOUT WIDTH -->
                    <div class="group mt-2">

                        <h2>Largura</h2>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="width">
                            <mat-radio-button color="primary" value="fullwidth">Tela Cheia</mat-radio-button>
                            <mat-radio-button color="primary" value="boxed">Caixa</mat-radio-button>
                        </mat-radio-group>

                    </div>

                    <!-- NAVBAR -->
                    <div class="group" formGroupName="navbar">

                        <h2>Menu Lateral</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="position">
                            <mat-radio-button color="primary" value="top">Topo</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="mt-2">Variante (Vertical):</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-2" formControlName="variant">
                            <mat-radio-button color="primary" value="vertical-style-1">Estilo 1</mat-radio-button>
                            <mat-radio-button color="primary" value="vertical-style-2">Estilo 2</mat-radio-button>
                        </mat-radio-group>

                        <h3 class="my-2">Cor de Fundo Primária:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="primaryBackground">
                        </cdk-material-color-picker>

                        <h3 class="my-2">Cor de Fundo Secundária (Vertical):</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="secondaryBackground">
                        </cdk-material-color-picker>

                    </div>

                    <!-- TOOLBAR -->
                    <div class="group" formGroupName="toolbar">

                        <h2>Menu Superior</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above">Acima</mat-radio-button>
                            <mat-radio-button color="primary" value="below">Abaixo</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor de Fundo:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- FOOTER -->
                    <div class="group" formGroupName="footer">

                        <h2>Rodapé</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="above-fixed">Acima Fixo</mat-radio-button>
                            <mat-radio-button color="primary" value="above-static">Acima Estático</mat-radio-button>
                        </mat-radio-group>

                        <mat-checkbox color="primary" class="mt-6" formControlName="customBackgroundColor">
                            Customizar Cor de Fundo
                        </mat-checkbox>

                        <h3 class="mt-6 mb-2">Cor de Fundo:</h3>
                        <cdk-material-color-picker class="mb-2" formControlName="background">
                        </cdk-material-color-picker>

                    </div>

                    <!-- SIDE PANEL -->
                    <div class="group" formGroupName="sidepanel">

                        <h2>Painel Lateral</h2>

                        <mat-slide-toggle color="primary" formControlName="hidden">
                            Escondido
                        </mat-slide-toggle>

                        <h3 class="mt-6">Posição:</h3>
                        <mat-radio-group class="flex flex-col justify-start items-start content-start gap-3" formControlName="position">
                            <mat-radio-button color="primary" value="left">Esquerda</mat-radio-button>
                            <mat-radio-button color="primary" value="right">Direita</mat-radio-button>
                        </mat-radio-group>

                    </div>

                </ng-container>

            </ng-container>

        </div>

        <!-- CUSTOM SCROLLBARS -->
        <div class="group hidden">

            <h2>Barra de Rolagem Customizada</h2>

            <mat-slide-toggle color="primary" class="mb-3" formControlName="customScrollbars">
                Habilitar
            </mat-slide-toggle>

        </div>

    </form>

</div>
