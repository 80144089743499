import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class CdkMatPaginatorIntl extends MatPaginatorIntl {
    changes = new Subject<void>();
    firstPageLabel = 'Primeiro';
    itemsPerPageLabel = ''; // ALTERADO PELA PGE-RS: deixado em branco.
    nextPageLabel = 'Seguinte';
    previousPageLabel = 'Anterior';
    lastPageLabel = 'Último';

    // ALTERADO PELA PGE-RS: para mostrar a qtde de registros totais da grid.
    getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0) {
            return `Nenhum registro`;
        }
        if (length === 1) {
            return `Pág. 1 (1 registro)`;
        }
        const amountPages = Math.ceil(length / pageSize);
        if (amountPages == 1) {
            return `Pág. 1 (${length} registros)`;
        }
        return `Pág. ${page + 1} de ${amountPages} (${length} registros)`;
    }
}
