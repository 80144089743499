import {Injectable} from '@angular/core';
import {CriteriaFieldProviderInterface} from '@cdk/components/criteria/contracts/criteria-field-provider.interface';
import CriteriaFieldInterface from '@cdk/components/criteria/contracts/criteria-field.interface';

@Injectable()
export class MainFieldsProvider implements CriteriaFieldProviderInterface{
    private _providers: CriteriaFieldProviderInterface[] = []

    public addProvider(provider: CriteriaFieldProviderInterface): this {
        if (!this._providers.includes(provider)) {
            this._providers.push(provider);
        }
        return this;
    }

    public getFields(context: any): CriteriaFieldInterface[]|null {
        let fields: CriteriaFieldInterface[] = [];
        this._providers
            .filter((provider: CriteriaFieldProviderInterface) => provider.supports(context))
            .forEach((provider: CriteriaFieldProviderInterface) => {
               fields = [
                   ...fields,
                   ...provider.getFields(context)
               ];
            });

        return fields
            .sort((firstField, secondField) => firstField.ordem - secondField.ordem);
    }

    public supports(context: any): boolean {
        return this.getFields(context).length > 0;
    }
}
