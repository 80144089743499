import {NgModule} from '@angular/core';
import {MainFieldsModule} from '@cdk/components/regra-etiqueta/providers/fields/main-fields.module';
import {MainFieldsProvider} from '@cdk/components/regra-etiqueta/providers/fields/main-fields.provider';

import {AssuntoAdministrativoService} from '@cdk/services/assunto-administrativo.service';
import {ClassificacaoService} from '@cdk/services/classificacao.service';
import {EspecieProcessoService} from '@cdk/services/especie-processo.service';
import {EspecieRelevanciaService} from '@cdk/services/especie-relevancia.service';
import {EspecieTarefaService} from '@cdk/services/especie-tarefa.service';
import {LocalizadorService} from '@cdk/services/localizador.service';
import {ModalidadeInteressadoService} from '@cdk/services/modalidade-interessado.service';
import {ModalidadeMeioService} from '@cdk/services/modalidade-meio.service';
import {ModalidadeOrgaoCentralService} from '@cdk/services/modalidade-orgao-central.service';
import {PessoaService} from '@cdk/services/pessoa.service';
import {SetorService} from '@cdk/services/setor.service';
import {
    IntimacaoCriacaoFieldsProvider
} from 'modules/judicial/@cdk/components/regra-etiqueta/providers/fields/intimacao/intimacao-criacao-fields.provider';
import {
    CriacaoProcessoJudicialFieldsProvider
} from 'modules/judicial/@cdk/components/regra-etiqueta/providers/fields/processo/criacao-processo-judicial-fields.provider';
import {ClasseNacionalService} from 'modules/judicial/@cdk/services/classe-nacional.service';
import {FonteDadosService} from 'modules/judicial/@cdk/services/fonte-dados.service';
import {OrgaoJulgadorService} from 'modules/judicial/@cdk/services/orgao-julgador.service';
import {TribunalService} from 'modules/judicial/@cdk/services/tribunal.service';

@NgModule({
    declarations: [],
    imports: [
        MainFieldsModule
    ],
    providers: [
        // Providers
        MainFieldsProvider,
        IntimacaoCriacaoFieldsProvider,
        CriacaoProcessoJudicialFieldsProvider,
        //Providers dependencies
        SetorService,
        AssuntoAdministrativoService,
        EspecieTarefaService,
        EspecieProcessoService,
        ClassificacaoService,
        ModalidadeMeioService,
        ModalidadeOrgaoCentralService,
        LocalizadorService,
        PessoaService,
        ModalidadeInteressadoService,
        EspecieRelevanciaService,
        ClasseNacionalService,
        OrgaoJulgadorService,
        TribunalService,
        FonteDadosService,
    ],
    exports: []
})
export class JudicialMainFieldModule {
    constructor(
        _mainFieldsProvider: MainFieldsProvider,
        _intimacaoCriacaoFieldsProvider: IntimacaoCriacaoFieldsProvider,
        _criacaoProcessoJudicialFieldsProvider: CriacaoProcessoJudicialFieldsProvider
    ) {
        _mainFieldsProvider
            .addProvider(_intimacaoCriacaoFieldsProvider)
            .addProvider(_criacaoProcessoJudicialFieldsProvider);
    }
}
