import {AbstractComparisonOperator} from '@cdk/components/criteria/operators/abstract-comparison-operator';
import {ExpressionPositionEnum} from '@cdk/components/criteria/operators/index';

export default class IsFalse extends AbstractComparisonOperator
{
    public readonly expression: string = 'eq:0';

    constructor(
        literalOperator: string = 'não',
        expressionPosition: ExpressionPositionEnum = ExpressionPositionEnum.MIDDLE
    ) {
        super(
            literalOperator,
            expressionPosition,
            false
        );
    }

    /**
     * Check if the expression pattern correspond to the operator.
     * @param expression
     */
    public checkExpression(expression: string): boolean {
        return expression === this.expression
    }

    /**
     * Returns expression value.
     * @param expression
     */
    public getValueFromExpression(expression: string): string|null {
        return null;
    }
}
