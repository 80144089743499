import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModelService} from '@cdk/services/model.service';
import {OrgaoJulgador} from '../models';
import {ParentGenericService} from '@cdk/services/parent-generic.service';

@Injectable()
export class OrgaoJulgadorService extends ParentGenericService<OrgaoJulgador> {
    constructor(
        protected modelService: ModelService,
        protected http: HttpClient,
    ) {
        super(modelService, 'judicial/orgao_julgador', OrgaoJulgador);
    }
}
