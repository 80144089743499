import {modulesConfigUtilPgers} from './util-pgers/modules-config';
import {modulesConfigProa} from './integracao-proa/modules-config';
//import {modulesConfigDividaPgers} from './divida-pgers/modules-config';

import {modulesConfigConsultivo} from './consultivo/modules-config';
import {modulesConfigDisciplinar} from './disciplinar/modules-config';
import {modulesConfigJudicial} from './judicial/modules-config';
// import {modulesConfigReceitaFederal} from './receita-federal/modules-config';
//import {modulesConfigDivida} from './divida/modules-config';
//import {modulesConfigGestaoDevedor} from './gestao-devedor/modules-config';
//import {modulesConfigCalculo} from './calculo/modules-config';
//import {modulesConfigEcarta} from './ecarta/modules-config';

export const modulesConfig: any = [
    modulesConfigUtilPgers,
    modulesConfigProa,
//    modulesConfigDividaPgers,

    modulesConfigConsultivo,
    modulesConfigDisciplinar,
    modulesConfigJudicial,
    // modulesConfigReceitaFederal,
//    modulesConfigDivida,
//    modulesConfigGestaoDevedor,
//    modulesConfigCalculo,
//    modulesConfigEcarta,
];
