import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModelService} from '@cdk/services/model.service';
import {ClasseNacional} from '../models';
import {ParentGenericService}  from '@cdk/services/parent-generic.service';

@Injectable()
export class ClasseNacionalService extends ParentGenericService<ClasseNacional> {
    constructor(
        protected modelService: ModelService,
        protected http: HttpClient,
    ) {
        super(modelService, 'judicial/classe_nacional', ClasseNacional);
    }
}
