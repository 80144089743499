import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ParentGenericService} from './parent-generic.service';
import {ModelService} from '@cdk/services/model.service';
import {Setor, PaginatedResponse} from '@cdk/models'; // ALTERADO PELA PGE-RS: /supp/supp-administrativo-backend/-/issues/53
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable()
export class SetorService extends ParentGenericService<Setor> {

    constructor(
        protected modelService: ModelService,
        protected http: HttpClient,
    ) {
        super(modelService, 'administrativo/setor', Setor);
    }

    transferirProcessosProtocolo(setores: any, context: any = '{}'): Observable<Setor> {
        const params: HttpParams = new HttpParams();
        params['context'] = context;
        return this.http.patch(
            `${environment.api_url}${'administrativo/setor'}/${setores.setorOrigem}/transferir_processos/${setores.setorDestino}` + environment.xdebug,
            null,
            {params}
        ).pipe(
            map((response) => {
                response = plainToClass(Setor, response);
                Object.keys(response).forEach(key => (response[key] === null) && delete response[key]);
                return Object.assign(new Setor(), {...setores, ...response});
            })
        );
    }

    transferirProcessosUnidade(setores: any, context: any = '{}'): Observable<Setor> {
        const params: HttpParams = new HttpParams();
        params['context'] = context;
        return this.http.patch(
            `${environment.api_url}${'administrativo/setor'}/${setores.setorOrigem}/transferir_processos_unidade/${setores.setorDestino}` + environment.xdebug,
            null,
            {params}
        ).pipe(
            map((response) => {
                response = plainToClass(Setor, response);
                Object.keys(response).forEach(key => (response[key] === null) && delete response[key]);
                return Object.assign(new Setor(), {...setores, ...response});
            })
        );
    }

    reindexarModelos(unidade: any, context: any = '{}'): Observable<Setor> {
        const params: HttpParams = new HttpParams();
        params['context'] = context;
        return this.http.patch(
            `${environment.api_url}${'administrativo/setor'}/${unidade.id}/reindexar_modelos` + environment.xdebug,
            null,
            {params}
        ).pipe(
            map((response) => {
                response = plainToClass(Setor, response);
                Object.keys(response).forEach(key => (response[key] === null) && delete response[key]);
                return Object.assign(new Setor(), {...unidade, ...response});
            })
        );
    }



    // ALTERADO PELA PGE-RS: INCLUÍDO: /supp/supp-administrativo-backend/-/issues/53
    // eslint-disable-next-line max-len
    queryProtocolosExternos(filters: any = '{}', limit: number = 25, offset: number = 0, order: any = '{}', populate: any = '[]', context: any = '{}', preload: any = null): Observable<PaginatedResponse> {
        const params = {};
        params['where'] = filters;
        params['limit'] = limit;
        params['offset'] = offset;
        params['order'] = order;
        params['populate'] = populate;
        params['context'] = context;        

        let path = 'util_pgers/setor/lista_protocolo_externos';

        return this.modelService.get(path, new HttpParams({fromObject: params}))
            .pipe(
                map((response: any) => {
                    this.modelService.eventsService.updatedStore$.emit(this.clz.name);
                    // retorna o response do server
                    return new PaginatedResponse(plainToClass(this.clz, response['entities']), response['total']);
                })
            );
    }



}
