import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ComponentFactory, ComponentRef,
    HostListener,
    Input,
    OnInit, QueryList, Renderer2,
    ViewChild, ViewChildren, ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';

import {cdkAnimations} from '@cdk/animations';
import {Pagination, Processo} from '@cdk/models';
import {ProcessoService} from '@cdk/services/processo.service';
import {AbstractControl} from '@angular/forms';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {of, Subscription, zip} from 'rxjs';
import {MatAutocomplete} from '@cdk/angular/material';
import {DynamicService} from 'modules/dynamic.service';
import {modulesConfig} from 'modules/modules-config';
import {CdkProcessoSearchAutocompleteSpan} from './cdk-processo-search-autocomplete-span';
import {Router} from '@angular/router';

@Component({
    selector: 'cdk-processo-search-autocomplete',
    templateUrl: './cdk-processo-search-autocomplete.component.html',
    styleUrls: ['./cdk-processo-search-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: cdkAnimations,
    exportAs: 'processoSearchAutocomplete',
})
export class CdkProcessoSearchAutocompleteComponent implements OnInit {

    @Input()
    pagination: Pagination;

    @Input()
    control: AbstractControl;

    @Input()
    searchField = 'NUP';

    @Input()
    openBlank = false;

    @ViewChild(MatAutocomplete, {static: true}) autocomplete: MatAutocomplete;
    mobileMode: boolean;

    dynamicCampos: CdkProcessoSearchAutocompleteSpan[] = [];

    processoSearchList: Processo[];
    processoSearchListIsLoading: boolean;

    private _dynamicColumnsLoaderSubscription: Subscription;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _processoService: ProcessoService,
        private _viewContainerRef: ViewContainerRef,
        private _render: Renderer2,
        private _dynamicService: DynamicService,
        private _router: Router
    ) {
        this.processoSearchList = [];
        this.processoSearchListIsLoading = false;

        this.pagination = new Pagination();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        const innerWidth = window.innerWidth;
        this.mobileMode = innerWidth <= 600;
    }

    fechado(): void {
        if (!this.control.value || typeof this.control.value === 'string' || !!this.control.value.id) {
            this.processoSearchList = [];
        }
    }

    ngOnInit(): void {
        this.control.valueChanges.pipe(
            tap(() => this.processoSearchList = []),
            debounceTime(300),
            distinctUntilChanged(),
            filter(term => !!term && term.length >= 4),
            switchMap((value: string) => {
                    const termFilter = [];
                    const filters = {};

                    if (this.searchField === 'outroNumero') {
                        filters[this.searchField] = `like:${value.trim()}%`;
                        termFilter.push(filters);
                        // value.split(' ').filter(bit => !!bit && bit.length >= 2).forEach((bit) => {
                        //     const filters = {};
                        //     filters[this.searchField] = `like:${bit}%`;
                        //     termFilter.push(filters);
                        // });
                    } else {
                        value = value.split('.').join('').split('/').join('').replace('-', '');
                        filters[this.searchField] = `like:${value.trim()}%`;
                        termFilter.push(filters);
                        // value = value.split('.').join('').split('/').join('').replace('-', '');
                        // value.split(' ').map(bit => bit.replace(/[^\d]+/g, '')).filter(bit => !!bit && bit.length >= 2).forEach((bit) => {
                        //     const filters = {};
                        //     filters[this.searchField] = `like:${bit}%`;
                        //     termFilter.push(filters);
                        // });
                    }

                    if (typeof value === 'string' && (termFilter.length)) {
                        this.processoSearchListIsLoading = true;
                        this._changeDetectorRef.detectChanges();
                        const filterParam = {
                            ...this.pagination.filter,
                            andX: termFilter
                        };
                        return this._processoService.query(
                            JSON.stringify(filterParam),
                            this.pagination.limit,
                            this.pagination.offset,
                            JSON.stringify(this.pagination.sort),
                            JSON.stringify(this.pagination.populate),
                            JSON.stringify(this.pagination.context)
                        ).pipe(
                            finalize(() => this.processoSearchListIsLoading = false),
                            catchError(() => of([]))
                        );
                    } else {
                        return of([]);
                    }
                }
            )
        ).subscribe((response) => {
            const path = '@cdk/components/processo/cdk-processo-search-autocomplete';

            if (this._dynamicColumnsLoaderSubscription) {
                this._dynamicColumnsLoaderSubscription.unsubscribe();
                this._dynamicColumnsLoaderSubscription = null;
            }

            response['entities'].forEach((processo) => {
                modulesConfig.forEach((module) => {
                    if (module.components.hasOwnProperty(path)) {
                        this._dynamicColumnsLoaderSubscription = zip(module.components[path].map(component => this._dynamicService.loadComponent(component)))
                            .subscribe((components) => {
                                components.forEach((componentFactory: ComponentFactory<CdkProcessoSearchAutocompleteSpan>) => {
                                    const componente: ComponentRef<CdkProcessoSearchAutocompleteSpan> = this._viewContainerRef.createComponent(componentFactory);
                                    componente.instance.processo = processo;

                                    this.dynamicCampos = [
                                        ...this.dynamicCampos.filter(column => column.tableColumn.id !== componente.instance.tableColumn.id),
                                        componente.instance
                                    ];
                                    this._changeDetectorRef.detectChanges();
                                });
                            });
                    }
                });
                this.processoSearchList = response['entities'];
            });
            this._changeDetectorRef.markForCheck();
        });

        const innerWidth = window.innerWidth;
        this.mobileMode = innerWidth <= 600;
    }

    displayProcessoFn(processoSearch: Processo): string {
        if (processoSearch) {
            if (this.searchField === 'outroNumero') {
                return processoSearch.outroNumero;
            } else {
                return processoSearch.NUPFormatado;
            }
        }
        return processoSearch ? processoSearch.NUPFormatado : '';
    }

    openNewTab(processo:Processo) {
        // this._router.navigate([`/apps/processo/${processo.id}/visualizar/capa/mostrar`]).then();
        const host = window.location.host;
        const url = `//${host}/apps/processo/${processo.id}/visualizar/capa/mostrar`;
        window.open(url, '_blank');
    }
}
