export interface CriteriaLoadDataResultInterface {
    data: any;
    getValor: () => string|number|boolean;
    getExibicao: () => string;
}

export function emptyCriteriaLoadDataResult(): CriteriaLoadDataResultInterface {
    return {
        data: '',
        getExibicao: () => '',
        getValor: () => ''
    }
}
