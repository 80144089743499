<div class="content" id="xxx">
<mat-autocomplete (closed)="fechado()" [displayWith]="displayProcessoFn">

    <mat-option *ngFor="let processo of processoSearchList"
                [value]="processo"
                [attr.processo-id]="processo.id"
                [ngClass]="{'multiline-auto-complete-options-responsive': mobileMode, 'multiline-auto-complete-options': !mobileMode}">
        <div class="icon-and-data">
            <div>
                <span [innerHTML]="displayProcessoFn(processo) | highlightOption: this.control.value" [ngClass]="{'span-block-responsive': mobileMode, 'span-block': !mobileMode}"></span>
                <span *ngIf="processo.titulo" [innerHTML]="'Título: ' + processo.titulo | titleCasePipe | highlightOption: this.control.value" [ngClass]="{'span-block-responsive': mobileMode, 'span-block': !mobileMode}">{{processo.titulo}}</span>
                <span *ngIf="processo.especieProcesso && processo.especieProcesso.nome" [innerHTML]="'Espécie: ' + processo.especieProcesso.nome | titleCasePipe | highlightOption: this.control.value" [ngClass]="{'span-block-responsive': mobileMode, 'span-block': !mobileMode}">{{processo.titulo}}</span>
                <span *ngIf="processo.especieProcesso && processo.especieProcesso.generoProcesso && processo.especieProcesso.generoProcesso.nome" [innerHTML]="'Gênero Processo: ' + processo.especieProcesso.generoProcesso.nome | titleCasePipe | highlightOption: this.control.value" [ngClass]="{'span-block-responsive': mobileMode, 'span-block': !mobileMode}">{{processo.titulo}}</span>
                <span *ngIf="processo.outroNumero" [innerHTML]="'Outro Número: ' + processo.outroNumero | highlightOption: this.control.value" [ngClass]="{'span-block-responsive': mobileMode, 'span-block': !mobileMode}">{{processo.titulo}}</span>
                <span *ngIf="processo.setorAtual && processo.setorAtual.nome && processo.setorAtual.nome === 'ARQUIVO'" [innerHTML]="'Setor Atual: ' + processo.setorAtual.nome | titleCasePipe | highlightOption: this.control.value" style="color: red;" [ngClass]="{'span-block-responsive': mobileMode, 'span-block': !mobileMode}">{{processo.titulo}}</span>
            </div>
            <div class="ml-8" *ngIf="openBlank">
                <mat-icon (click)="openNewTab(processo)" svgIcon="mat_solid:open_in_new"></mat-icon>
            </div>
        </div>
        <ng-container *ngFor="let dynamicColumn of dynamicCampos">
            <span [innerHTML]="(dynamicColumn.tableColumn.dataValue ? dynamicColumn.tableColumn.dataValue(processo, this) : '') | safeHtml" [ngClass]="{'span-block-responsive': mobileMode, 'span-block': !mobileMode}"></span>
        </ng-container>
        <br>
        <mat-divider *ngIf="mobileMode"></mat-divider>
    </mat-option>
    <mat-option *ngIf="processoSearchListIsLoading">
        <mat-spinner color="primary" diameter="16"></mat-spinner>
    </mat-option>

</mat-autocomplete>
</div>

