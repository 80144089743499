import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {getRouterState, State} from 'app/store/reducers';
import * as AssinaturaActions from '../actions/assinatura.actions';
import {AddData, UpdateData} from '@cdk/ngrx-normalizr';
import {Assinatura, Documento} from '@cdk/models';
import {assinatura as assinaturaSchema, documento as documentoSchema} from '@cdk/normalizr';
import * as OperacoesActions from 'app/store/actions/operacoes.actions';
import {AssinaturaService} from '@cdk/services/assinatura.service';
import {DocumentoService} from '@cdk/services/documento.service';
import * as AssinaturaStore from '../index';

@Injectable()
export class AssinaturaEffects {
    routerState: any;

    /**
     * Assina Documento
     *
     * @type {Observable<any>}
     */
    assinaDocumento: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.AssinaDocumento>(AssinaturaActions.ASSINA_DOCUMENTO),
        mergeMap(action => this._documentoService.preparaAssinatura(JSON.stringify(action.payload))
            .pipe(
                map(response => new AssinaturaActions.PreparaAssinaturaSuccess(response)),
                catchError((err) => {
                    const payload = {
                        ids: action.payload,
                        error: err
                    };
                    console.log(err);
                    return of(new AssinaturaActions.PreparaAssinaturaFailed(payload));
                })
            )
        )
    ));

    /**
     * Assina Documentos (e inclui ou não anexos)
     *
     * @type {Observable<any>}
     */
    assinaDocumentos: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.AssinaDocumentos>(AssinaturaActions.ASSINA_DOCUMENTOS),
        mergeMap(action => this._assinaturaService.documentos(
            'a3:' + localStorage.getItem('assinador'),
            JSON.stringify(action.payload.documentosId),
            action.payload.incluiAnexos,
            action.payload.pades,
        ).pipe(
            map(response => new AssinaturaActions.AssinaDocumentosSuccess(response['entities']),),
            catchError((err) => {
                const payload = {
                    ids: action.payload.documentosId,
                    error: err
                };
                console.log(err);
                return of(new AssinaturaActions.AssinaDocumentosFailed(payload));
            })
        ))
    ));

    /**
     * Assina Documento Eletronicamente
     *
     * @type {Observable<any>}
     */
     assinaDocumentoEletronicamente: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.AssinaDocumentoEletronicamente>(AssinaturaActions.ASSINA_DOCUMENTO_ELETRONICAMENTE),
        tap(action => this._store.dispatch(new OperacoesActions.Operacao({
            id: action.payload.operacaoId,
            type: 'assinatura',
            content: 'Assinando documento id ' + action.payload.documento.id + ' ...',
            status: 0, // carregando
            lote: action.payload.loteId
        }))),
        mergeMap(action => this._assinaturaService.save(action.payload.assinatura).pipe(
            tap(response => this._store.dispatch(new OperacoesActions.Operacao({
                id: action.payload.operacaoId,
                type: 'assinatura',
                content: 'Assinatura documento id ' + action.payload.documento.id + ' criada com sucesso.',
                status: 1, // sucesso
                lote: action.payload.loteId
            }))),
            mergeMap((response: Assinatura) => [
                new AssinaturaActions.AssinaDocumentoEletronicamenteSuccess(action.payload.documento.id),
                new AddData<Assinatura>({data: [response], schema: assinaturaSchema}),
                new UpdateData<Documento>({
                    id: action.payload.documento.id,
                    schema: documentoSchema,
                    changes: {assinado: true}
                })
            ]),
            catchError((err) => {
                const payload = {
                    documentoId: action.payload.documento.id,
                    error: err
                };
                console.log(err);
                this._store.dispatch(new OperacoesActions.Operacao({
                    id: action.payload.operacaoId,
                    type: 'assinatura',
                    content: 'Ocorreu um erro na assinatura do documento id ' + action.payload.documento.id + '.',
                    status: 2, // erro
                    lote: action.payload.loteId
                }));
                return of(new AssinaturaActions.AssinaDocumentoEletronicamenteFailed(payload));
            })
        ))
    ));

    /**
     * Assina Documentos Eletronicamente
     *
     * @type {Observable<any>}
     */
    assinaDocumentosEletronicamente: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.AssinaDocumentosEletronicamente>(AssinaturaActions.ASSINA_DOCUMENTOS_ELETRONICAMENTE),
        tap(action => this._store.dispatch(new OperacoesActions.Operacao({
            id: action.payload.operacaoId,
            type: 'assinatura',
            content: 'Assinando documentos selecionados para destinação...',
            status: 0, // carregando
            lote: action.payload.loteId
        }))),
        mergeMap(action => this._assinaturaService.documentos(
            'a1:' + action.payload.plainPassword,
            JSON.stringify(action.payload.documentosId),
            action.payload.incluiAnexos,
            action.payload.pades,
        ).pipe(
            tap(response => this._store.dispatch(new OperacoesActions.Operacao({
                id: action.payload.operacaoId,
                type: 'assinatura',
                content: 'Assinatura de documentos selecionados para destinação realizada com sucesso.',
                status: 1, // sucesso
                lote: action.payload.loteId
            }))),
            mergeMap((response) => [
                new AssinaturaActions.AssinaDocumentosSuccess(response['entities']),
            ]),
            catchError((err) => {
                const payload = {
                    ids: action.payload.documentosId,
                    error: err
                };
                console.log(err);
                this._store.dispatch(new OperacoesActions.Operacao({
                    id: action.payload.operacaoId,
                    type: 'assinatura',
                    content: 'Ocorreu um erro na assinatura dos documentos.',
                    status: 2, // erro
                    lote: action.payload.loteId
                }));
                return of(new AssinaturaActions.AssinaDocumentosEletronicamenteFailed(payload));
            })
        ))
    ));

    revalidaLoginGovBr: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.RevalidaLoginGovBR>(AssinaturaActions.REVALIDA_LOGIN_GOVBR),
        mergeMap(action => {

            return this._assinaturaService.getTokenRevalidaLoginGovBr(action.payload.code, action.payload.state).pipe(
                map((data: any) => {
                    if (window.opener) {
                        localStorage.setItem('tokenRevalidaGovBr', data.jwt);
                        window.opener.postMessage('closeRevalidaGovBrSuccess', '*');
                        window.close();
                    }
                    return new AssinaturaActions.AssinaDocumentoEletronicamenteGovBrSuccess(data);
                }),
                catchError((err) => {
                    const payload = {
                        error: err
                    };
                    console.log(err);
                    this._store.dispatch(new OperacoesActions.Operacao({
                        id: action.payload.operacaoId,
                        type: 'assinatura',
                        content: 'Ocorreu um erro na assinatura do documento via govBr id ' + action.payload.documento.id + '.',
                        status: 2, // erro
                        lote: action.payload.loteId
                    }));
                    return of(new AssinaturaActions.AssinaDocumentoEletronicamenteFailed(payload));
                })
            )
        })
    ));

    /**
     * Remover Assinatura Documento
     *
     * @type {Observable<any>}
     */
    removeAssinaturaDocumento: Observable<any> = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.RemoveAssinaturaDocumento>(AssinaturaActions.REMOVE_ASSINATURA_DOCUMENTO),
        mergeMap(action => this._documentoService.removeAssinatura(action.payload).pipe(
            mergeMap(() => [
                new AssinaturaActions.RemoveAssinaturaDocumentoSuccess(action.payload),
                new UpdateData<Documento>({
                    id: action.payload,
                    schema: documentoSchema,
                    changes: {assinado: false}
                })
            ]),
            catchError((err) => {
                console.log(err);
                return of(new AssinaturaActions.RemoveAssinaturaDocumentoFailed(action.payload));
            })
        ), 25)
    ));

    /**
     * Remover Assinatura PAdES
     *
     * @type {Observable<any>}
     */
    removeAssinaturaPades: Observable<any> = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.RemoveAssinaturaPades>(AssinaturaActions.REMOVE_ASSINATURA_PADES),
        mergeMap(action => this._documentoService.removeAssinaturaPades(action.payload).pipe(
            mergeMap(() => [
                new AssinaturaActions.RemoveAssinaturaPadesSuccess(action.payload),
            ]),
            catchError((err) => {
                console.log(err);
                return of(new AssinaturaActions.RemoveAssinaturaPadesFailed(action.payload));
            })
        ), 25)
    ));

    /**
     * Assina Documento NeoId
     *
     * @type {Observable<any>}
     */
    assinaDocumentoNeoId: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.AssinaDocumentoEletronicamenteNeoId>(AssinaturaActions.ASSINA_DOCUMENTO_ELETRONICAMENTE_NEOID),
        tap(action => this._store.dispatch(new OperacoesActions.Operacao({
            id: action.payload.operacaoId,
            type: 'assinatura',
            content: 'Assinando documentos com neoid',
            status: 0, // carregando
            lote: action.payload.operacaoId
        }))),
        mergeMap(action => this._assinaturaService.assinaNeoId(
            action.payload.assinatura,
            '{}',
            JSON.stringify(['componenteDigital','componenteDigital.documento'])
        ).pipe(
            tap(response => this._store.dispatch(new OperacoesActions.Operacao({
                id: action.payload.operacaoId,
                type: 'assinatura',
                content: 'Assinaturas neoid criadas com sucesso.',
                status: 1, // sucesso
                lote: action.payload.loteId
            }))),
            mergeMap((response) => {
                const actions = [];
                response['entities'].forEach(entity => {
                    actions.push(new AssinaturaActions.AssinaDocumentoEletronicamenteSuccess(
                        entity.componenteDigital?.documento?.id)
                    );
                    actions.push(new UpdateData<Documento>({
                        id: entity.componenteDigital?.documento?.id,
                        schema: documentoSchema,
                        changes: {assinado: true}
                    }));
                });
                return [
                    new AddData<Assinatura>({data: response['entities'], schema: assinaturaSchema}),
                    ...actions
                ];
            }),
            catchError((err) => {
                const actions = [];
                action.payload.documentos.forEach(e => {
                    const payload = {
                        documentoId: e,
                        error: err
                    };
                    actions.push(new AssinaturaActions.AssinaDocumentoEletronicamenteFailed(payload));
                });
                console.log(err);
                this._store.dispatch(new OperacoesActions.Operacao({
                    id: action.payload.operacaoId,
                    type: 'assinatura',
                    content: 'Ocorreu um erro na assinatura com neoid.',
                    status: 2, // erro
                    lote: action.payload.loteId
                }));
                return of(actions);
            })
        ))
    ));

    /**
     * Assina Documentos NeoId
     *
     * @type {Observable<any>}
     */
    assinaDocumentosNeoId: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.AssinaDocumentosEletronicamenteNeoId>(AssinaturaActions.ASSINA_DOCUMENTOS_ELETRONICAMENTE_NEOID),
        tap(action => this._store.dispatch(new OperacoesActions.Operacao({
            id: action.payload.operacaoId,
            type: 'assinatura',
            content: 'Assinando documentos com neoid',
            status: 0, // carregando
            lote: action.payload.operacaoId
        }))),
        mergeMap(action => this._assinaturaService.documentos(
            action.payload.credentials,
            JSON.stringify(action.payload.documentosId),
            action.payload.incluiAnexos,
            action.payload.pades
        ).pipe(
            tap(response => this._store.dispatch(new OperacoesActions.Operacao({
                id: action.payload.operacaoId,
                type: 'assinatura',
                content: 'Assinaturas neoid criadas com sucesso.',
                status: 1, // sucesso
                lote: action.payload.loteId
            }))),
            mergeMap((response) => [
                new AssinaturaActions.AssinaDocumentosSuccess(response['entities']),
            ]),
            catchError((err) => {
                const payload = {
                    ids: action.payload.documentosId,
                    error: err
                };
                console.log(err);
                this._store.dispatch(new OperacoesActions.Operacao({
                    id: action.payload.operacaoId,
                    type: 'assinatura',
                    content: 'Ocorreu um erro na assinatura dos documentos.',
                    status: 2, // erro
                    lote: action.payload.loteId
                }));
                return of(new AssinaturaActions.AssinaDocumentosEletronicamenteFailed(payload));
            })
        ))
    ));

    assinaDocumentosSuccess: any = createEffect(() => this._actions.pipe(
        ofType<AssinaturaActions.AssinaDocumentosSuccess>(AssinaturaActions.ASSINA_DOCUMENTOS_SUCCESS),
        tap((action) => {
            if (action.payload?.length > 0) {
                // Assinatura síncrona, backend retornou todas as entities de assinaturas
                this._store.dispatch(new AddData<Assinatura>({data: action.payload, schema: assinaturaSchema}));
                // Atualizar documentos e marcar como assinados
                action.payload.forEach((assinatura: Assinatura) => {
                    this._store.dispatch(new AssinaturaStore.AssinaDocumentoSuccess(assinatura.componenteDigital?.documento?.id));
                    this._store.dispatch(new UpdateData<Documento>({
                        id: assinatura.componenteDigital?.documento?.id,
                        schema: documentoSchema,
                        changes: {assinado: true}
                    }))
                })
            }
        }),
    ), {dispatch: false});

    constructor(
        private _actions: Actions,
        private _documentoService: DocumentoService,
        private _assinaturaService: AssinaturaService,
        private _store: Store<State>
    ) {
        this._store.pipe(
            select(getRouterState),
            filter(routerState => !!routerState)
        ).subscribe((routerState) => {
            this.routerState = routerState.state;
        });
    }
}
