import * as moment from 'moment';
import {Exclude, Transform, Type} from 'class-transformer';
import {Usuario, Formulario, ComponenteDigital, Documento} from '@cdk/models';

export class DadosFormulario {

    @Exclude({toPlainOnly: true})
    id?: number;

    @Exclude({toPlainOnly: true})
    uuid?: string;

    dataValue?: string;

    @Type(() => Formulario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    formulario?: Formulario | null;

    @Type(() => ComponenteDigital)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    componenteDigital?: ComponenteDigital | null;

    @Type(() => Documento)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    documento?: Documento | null;

    invalido?: boolean;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    criadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    criadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    atualizadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    atualizadoEm?: moment.Moment;

    @Exclude({toPlainOnly: true})
    @Type(() => Usuario)
    @Transform(value => value ? value.id : null, {toPlainOnly: true})
    apagadoPor?: Usuario;

    @Exclude({toPlainOnly: true})
    @Transform(value => value ? value.format() : null, {toPlainOnly: true})
    @Transform(value => value ? moment(value) : null, {toClassOnly: true})
    apagadoEm?: moment.Moment;

    constructor() {
        this.id = null;
        this.uuid = null;
        this.dataValue = null;
        this.formulario = null;
        this.componenteDigital = null;
        this.documento = null;
        this.invalido = false;
        this.criadoPor = null;
        this.criadoEm = null;
        this.atualizadoPor = null;
        this.atualizadoEm = null;
        this.apagadoPor = null;
        this.apagadoEm = null;
    }
}
