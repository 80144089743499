import {Observable, of} from 'rxjs';
import {Processo} from '../../@cdk/models';
import {filter, switchMap} from 'rxjs/operators';
import {RoleGuard} from '../../app/main/apps/role.guard';
import {CdkNavigationItem} from '../../@cdk/types';

export const modulesConfigDisciplinar = {
    name: 'correicional', // ALTERADO PELA PGE
    routes: {
        'app/main/apps': [
            {
                path: 'processo-preliminar',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo-preliminar/processo-preliminar.module'
                    ).then(m => m.ProcessoPreliminarModule),
                canActivate: [RoleGuard],
                data: {roles: ['ROLE_DISCIPLINAR']}
            },
            {
                path: 'processo-disciplinar',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo-disciplinar/processo-disciplinar.module'
                    ).then(m => m.ProcessoDisciplinarModule),
                canActivate: [RoleGuard],
                data: {roles: ['ROLE_DISCIPLINAR']}
            }
        ],
        'app/main/apps/admin': [
            {
                path: 'classes',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/admin/classe/classe.module'
                    ).then(m => m.ClasseModule)
            },
            {
                path: 'especie-fases',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/admin/especie-fase/especie-fase.module'
                    ).then(m => m.EspecieFaseModule)
            },
            {
                path: 'motivos',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/admin/motivo/motivo.module'
                    ).then(m => m.MotivoModule)
            },
            {
                path: 'origens-representacao',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/admin/origem-representacao/origem-representacao.module'
                    ).then(m => m.OrigemRepresentacaoModule)
            },
            {
                path: 'resultados',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/admin/resultado/resultado.module'
                    ).then(m => m.ResultadoModule
                )
            },
        ],
        'app/main/apps/processo/processo-edit': [
            {
                path: 'dados-basicos-disciplinar',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/dados-basicos/dados-basicos-pad/dados-basicos-pad.module'
                    ).then(m => m.DadosBasicosPadModule),
            },
            {
                path: 'dados-basicos-processo-juizo',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/dados-basicos/dados-basicos-processo-juizo/dados-basicos-processo-juizo.module'
                    ).then(m => m.DadosBasicosProcessoJuizoModule),
            },
            {
                path: 'impedimentos-suspeicao-disciplinar',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/impedimento/impedimento.module'
                    ).then(m => m.ImpedimentoModule),
            },
            {
                path: 'membros-comissao',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/membro-comissao/membro-comissao.module'
                    ).then(m => m.MembroComissaoModule),
            },
            {
                path: 'fases',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/fase/fase.module'
                    ).then(m => m.FaseModule),
            },
            {
                path: 'resultados-processo',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/resultado-juizo/resultado-juizo.module'
                    ).then(m => m.ResultadoJuizoModule
                ),
            },
            {
                path: 'setores-responsaveis',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/setor-responsavel/setor-responsavel.module'
                    ).then(m => m.SetorResponsavelModule
                ),
            },
            {
                path: 'unidades-relacionadas',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/unidade-relacionada/unidade-relacionada.module'
                    ).then(m => m.UnidadeRelacionadaModule
                ),
            },
            {
                path: 'causas-prescritivas',
                loadChildren: () => import(
                    'modules/disciplinar/app/main/apps/processo/processo-edit/causa-prescritiva/causa-prescritiva.module'
                    ).then(m => m.CausaPrescritivaModule
                ),
            }
        ]
    },
    mainMenu: [
        {
            id: 'tarefas',
            entries: [{
                id: 'tarefas_pendentes_correicional',
                title: 'Correicional', // ALTERADO PELA PGE
                type: 'item',
                icon: 'attribution', // ALTERADO PELA PGE-RS: ícone
                hideable: true,
                url: '/apps/tarefas/correicional/minhas-tarefas/entrada',
                badge: {
                    title: '0',
                    bg : '#F44336',
                    fg: '#FFFFFF'
                },
                role: 'ROLE_DISCIPLINAR',
                badgeCompartilhada: {
                    id: 'tarefas_compartilhadas_correicional',
                    parent: false,
                    badge: {
                        title: '0',
                        bg: '#fc6054',
                        fg: '#FFFFFF'
                    }
                },
                canHide: (item: CdkNavigationItem): boolean => {
                    let countBadges = 0;
                    countBadges = parseInt(item.badge?.title, 10);
                    if (item.badgeCompartilhada) {
                        countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                    }
                    return countBadges === 0;
                }
            }]
        },
        {
            id: 'admin',
            entries: [
                {
                    id: 'classes',
                    title: 'Classe',
                    type: 'item',
                    icon: 'book',
                    url: '/apps/admin/classes'
                },
                {
                    id: 'especieFases',
                    title: 'Especie Fase',
                    type: 'item',
                    icon: 'book',
                    url: '/apps/admin/especie-fases'
                },
                {
                    id: 'motivo',
                    title: 'Motivo',
                    type: 'item',
                    icon: 'book',
                    url: '/apps/admin/motivos'
                },
                {
                    id: 'origensRepresentacao',
                    title: 'Origem Representacao',
                    type: 'item',
                    icon: 'book',
                    url: '/apps/admin/origens-representacao'
                },
                {
                    id: 'resultado',
                    title: 'Resultados',
                    type: 'item',
                    icon: 'book',
                    url: '/apps/admin/resultados'
                }
            ]
        },
        {
            id: 'protocolo',
            entries: [
                {
                    id: 'disciplinar',
                    title: 'Correicional',
                    type: 'collapsable',
                    icon: 'attribution', // ALTERADO PELA PGE-RS: ícone
                    hideable: false,
                    allowGroupOfHiddenChildren: false,
                    startExpanded: true, // ALTERADO PELA PGE-RS: adicionado.
                    role: 'ROLE_DISCIPLINAR',
                    children: [
                        {
                            id: 'processoJuizo',
                            title: 'Preliminar', // ALTERADO PELA PGE
                            type: 'item',
                            icon: 'book',
                            hideable: false,
                            url: '/apps/processo-preliminar/criar/editar/dados-basicos-steps',
                            role: 'ROLE_DISCIPLINAR'
                        },
                        {
                            id: 'processoPad',
                            title: 'Disciplinar', // ALTERADO PELA PGE
                            type: 'item',
                            icon: 'book',
                            hideable: false,
                            url: '/apps/processo-disciplinar/criar/editar/dados-basicos-steps',
                            role: 'ROLE_DISCIPLINAR'
                        }
                    ]
                }
            ]
        }
    ],
    sidebars: {
        'app/main/apps/admin/sidebars/main': [
            {
                nome: 'Classes',
                icon: 'person',
                link: 'classes',
                role: 'ROLE_ADMIN'
            },
            {
                nome: 'Espécie Fases',
                icon: 'drag_indicator',
                link: 'especie-fases',
                role: 'ROLE_ADMIN'
            },
            {
                nome: 'Motivos',
                icon: 'contact_support',
                link: 'motivos',
                role: 'ROLE_ADMIN'
            },
            {
                nome: 'Origem Representação',
                icon: 'toll',
                link: 'origens-representacao',
                role: 'ROLE_ADMIN'
            },
            {
                nome: 'Resultados',
                icon: 'menu',
                link: 'resultados',
                role: 'ROLE_ADMIN'
            },
        ],
        /** ALTERADO PELA PGE-RS: label e index. */
        'app/main/apps/processo/processo-edit/sidebars/main': [
            {
                index: 11,
                nome: 'Processo Disciplinar',
                link: 'editar/dados-basicos-disciplinar',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            if (processo.acessoNegado || processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR') {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 11,
                nome: 'Processo Preliminar',
                link: 'editar/dados-basicos-processo-juizo',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            if (processo.acessoNegado || processo.especieProcesso.nome !== 'CONTROLE DE JUÍZO DE ADMISSIBILIDADE') {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 14,
                nome: 'Impedimentos / Suspeição',
                link: 'editar/impedimentos-suspeicao-disciplinar',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            // tslint:disable-next-line:max-line-length
                            if (processo.acessoNegado || (processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR' && processo.especieProcesso.nome !== 'CONTROLE DE JUÍZO DE ADMISSIBILIDADE')) {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 15,
                nome: 'Membros da Comissão',
                link: 'editar/membros-comissao',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            if (processo.acessoNegado || processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR') {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 16,
                nome: 'Resultados do Processo',
                link: 'editar/resultados-processo',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            // tslint:disable-next-line:max-line-length
                            if (processo.acessoNegado || (processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR' && processo.especieProcesso.nome !== 'CONTROLE DE JUÍZO DE ADMISSIBILIDADE')) {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 13,
                nome: 'Fases',
                link: 'editar/fases',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            if (processo.acessoNegado || processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR') {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 17,
                nome: 'Setores Responsáveis',
                link: 'editar/setores-responsaveis',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            // tslint:disable-next-line:max-line-length
                            if (processo.acessoNegado || (processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR' && processo.especieProcesso.nome !== 'CONTROLE DE JUÍZO DE ADMISSIBILIDADE')) {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 18,
                nome: 'Unidades Relacionadas',
                link: 'editar/unidades-relacionadas',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            // tslint:disable-next-line:max-line-length
                            if (processo.acessoNegado || (processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR' && processo.especieProcesso.nome !== 'CONTROLE DE JUÍZO DE ADMISSIBILIDADE')) {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
            {
                index: 12,
                nome: 'Causas Interruptivas e Suspensivas',
                link: 'editar/causas-prescritivas',
                role: 'ROLE_DISCIPLINAR',
                canShow: (processo$: Observable<Processo>): Observable<boolean> => processo$.pipe(
                        filter(processo => !!processo),
                        switchMap((processo) => {
                            // tslint:disable-next-line:max-line-length
                            if (processo.acessoNegado || (processo.especieProcesso.nome !== 'PROCESSO ADMINISTRATIVO DISCIPLINAR' && processo.especieProcesso.nome !== 'CONTROLE DE JUÍZO DE ADMISSIBILIDADE')) {
                                return of(false);
                            }
                            return of(true);
                        })
                    )
            },
        ]
    },
    routerLinks: {},
    components: {
        'app/main/apps/processo#converter': [
            () => import('modules/disciplinar/app/main/apps/processo/processo.module').then(m => m.ProcessoModule)
        ],
		// ALTERADO PELA PGE-RS: adicionado.
        'app/main/apps/processo/processo-capa': [
            () => import('modules/disciplinar/app/main/apps/processo/processo-capa/processo-capa.module').then(m => m.ProcessoCapaModule)
        ],
        '@cdk/components/processo/sidebars/cdk-processo-filter': [
            // tslint:disable-next-line:max-line-length
            () => import('modules/disciplinar/@cdk/components/processo/sidebars/cdk-processo-disciplinar-filter/cdk-processo-disciplinar-filter.module').then(m => m.CdkProcessoDisciplinarFilterModule)
        ],
    },
    ajuda: [],
    widgets: [],
    generoAffinity: {},
    navigationConverter: {
        mainMenu: {}
    }
};
