import {EnvironmentProviders, ModuleWithProviders, NgModule, Provider, Type} from '@angular/core';
import {
    JudicialMainFieldModule
} from 'modules/judicial/@cdk/components/regra-etiqueta/providers/fields/judicial-main-field.module';
import {ModuleExtension} from 'modules/module.extension';

@NgModule()
export class JudicialExtensionModule implements ModuleExtension<JudicialExtensionModule> {
    forChild(path?: string): Array<Provider | EnvironmentProviders> {
        return [];
    }

    forRoot(): ModuleWithProviders<JudicialExtensionModule> {
        return {
            ngModule : JudicialExtensionModule,
            providers: [],
        };
    }

    imports(path?: string): Array<Type<any> | ModuleWithProviders<{}> | any[]> {
        let imports = [];

        switch (path) {
            case 'app/main/apps/configuracoes/etiquetas/etiqueta-edit/regras-etiqueta/regra-etiqueta-edit':
            case 'app/main/apps/coordenador/etiquetas/etiqueta-edit/regras-etiqueta/regra-etiqueta-edit':
                imports.push(JudicialMainFieldModule);
                break;
        }
        return imports;
    }

    getName(): string {
        return 'supp-judicial-frontend';
    }

    getVersion(): string {
        return '';
    }

    manageRoutes(path: string, routes?: any[]): any[] {
        return routes;
    }

    manageInterceptors(path: string, interceptors?: any[]): any[] {
        return interceptors;
    }

    manageMainMenu(mainMenu: any[]): any[] {
        return mainMenu;
    }

    manageRouterLinks(path: string, routerLinks?: any[]): string {
        return '';
    }

    manageComponents(path: string, components?: any[]): any[] {
        return components;
    }

    manageAjudas(path: string, ajudas?: any[]): any[] {
        return ajudas;
    }

    manageWidgets(path: string, widgets?: any[]): any[] {
        return widgets;
    }

    manageNotifications(path: string, notifications?: any[]): any[] {
        return notifications;
    }

    manageGeneroAfinity(path: string, model?:any): string {
        return '';
    }

    navigationConverter(menu: string): any {
        return null;
    }
}
