import * as AssinaturaActions from '../actions/assinatura.actions';

export interface AssinaturaState {
    assinandoDocumentosId: number[];
    removendoAssinaturaDocumentosId: number[];
    removendoAssinaturaPadesId: number[];
    errosAssinaturaDocumentosId: number[];
    assinandoAnexos: boolean;
    errors: any;
    redirectRevalidaGovBr: boolean,
    getTokenNeoId: boolean
}

export const assinaturaInitialState: AssinaturaState = {
    assinandoDocumentosId: [],
    removendoAssinaturaDocumentosId: [],
    removendoAssinaturaPadesId: [],
    errosAssinaturaDocumentosId: [],
    assinandoAnexos: false,
    errors: false,
    redirectRevalidaGovBr: false,
    getTokenNeoId: false
};

export const assinaturaReducer = (
    state = assinaturaInitialState,
    action: AssinaturaActions.AssinaturaActionsAll
): AssinaturaState => {
    switch (action.type) {

        case AssinaturaActions.ASSINA_DOCUMENTO: {
            return {
                ...state,
                assinandoDocumentosId: [...state.assinandoDocumentosId, ...action.payload],
                errosAssinaturaDocumentosId: state.errosAssinaturaDocumentosId.filter(el => !action.payload.includes(el)),
                errors: false
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTO_SUCCESS: {
            return {
                ...state,
                assinandoDocumentosId: state.assinandoDocumentosId.filter(id => id !== action.payload),
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTO_FAILED: {
            return {
                ...state,
                assinandoDocumentosId: state.assinandoDocumentosId.filter(id => id !== action.payload.documentoId),
                errosAssinaturaDocumentosId: [...state.errosAssinaturaDocumentosId, action.payload.documentoId],
                errors: {
                    ...state.errors, // Mantém os erros anteriores
                    [action.payload.documentoId]: action.payload.error // Associa o erro ao documentoId
                }
            };
        }
        

        case AssinaturaActions.ASSINA_DOCUMENTOS: {
            return {
                ...state,
                assinandoDocumentosId: [...state.assinandoDocumentosId, ...action.payload.documentosId],
                errosAssinaturaDocumentosId: state.errosAssinaturaDocumentosId.filter(el => !action.payload.documentosId.includes(el)),
                assinandoAnexos: action.payload.incluiAnexos,
                errors: false
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTOS_FAILED: {
            return {
                ...state,
                assinandoDocumentosId: state.assinandoDocumentosId.filter(el => !action.payload.ids.includes(el)),
                errosAssinaturaDocumentosId: [...state.errosAssinaturaDocumentosId, ...action.payload.ids],
                errors: action.payload.error
            };
        }

        case AssinaturaActions.PREPARA_ASSINATURA_FAILED: {
            return {
                ...state,
                assinandoDocumentosId: state.assinandoDocumentosId.filter(el => action.payload.ids.includes(el)),
                errosAssinaturaDocumentosId: [...state.errosAssinaturaDocumentosId, ...action.payload.ids],
                errors: action.payload.error
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTO_ELETRONICAMENTE: {
            return {
                ...state,
                assinandoDocumentosId: [...state.assinandoDocumentosId, action.payload.documento.id],
                errosAssinaturaDocumentosId: state.errosAssinaturaDocumentosId.filter(id => id !== action.payload.documento.id),
                errors: false
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTO_ELETRONICAMENTE_SUCCESS: {
            return {
                ...state,
                assinandoDocumentosId: state.assinandoDocumentosId.filter(id => id !== action.payload),
                errors: false
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTO_ELETRONICAMENTE_FAILED: {
            return {
                ...state,
                assinandoDocumentosId: state.assinandoDocumentosId.filter(id => id !== action.payload.documentoId),
                errosAssinaturaDocumentosId: [...state.errosAssinaturaDocumentosId, action.payload.documentoId],
                errors: action.payload.error
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTOS_ELETRONICAMENTE: {
            return {
                ...state,
                assinandoDocumentosId: [...state.assinandoDocumentosId, ...action.payload.documentosId],
                errosAssinaturaDocumentosId: state.errosAssinaturaDocumentosId.filter(el => !action.payload.documentosId.includes(el)),
                assinandoAnexos: action.payload.incluiAnexos,
                errors: false
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTOS_ELETRONICAMENTE_FAILED: {
            return {
                ...state,
                assinandoDocumentosId: state.assinandoDocumentosId.filter(el => !action.payload.ids.includes(el)),
                errosAssinaturaDocumentosId: [...state.errosAssinaturaDocumentosId, ...action.payload.ids],
                errors: action.payload.error
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTO_ELETRONICAMENTE_GOVBR: {
            return {
                ...state,
                redirectRevalidaGovBr: true
            };
        }

        case AssinaturaActions.REMOVE_ASSINATURA_DOCUMENTO: {
            return {
                ...state,
                removendoAssinaturaDocumentosId: [...state.removendoAssinaturaDocumentosId, action.payload]
            };
        }

        case AssinaturaActions.REMOVE_ASSINATURA_DOCUMENTO_SUCCESS: {
            return {
                ...state,
                removendoAssinaturaDocumentosId: state.removendoAssinaturaDocumentosId.filter(id => id !== action.payload)
            };
        }

        case AssinaturaActions.REMOVE_ASSINATURA_DOCUMENTO_FAILED: {
            return {
                ...state,
                removendoAssinaturaDocumentosId: state.removendoAssinaturaDocumentosId.filter(id => id !== action.payload)
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTO_ELETRONICAMENTE_NEOID: {
            return {
                ...state,
                assinandoDocumentosId: [...state.assinandoDocumentosId, ...action.payload.documentos],
                errors: false
            };
        }

        case AssinaturaActions.ASSINA_DOCUMENTOS_ELETRONICAMENTE_NEOID: {
            return {
                ...state,
                assinandoDocumentosId: [...state.assinandoDocumentosId, ...action.payload.documentosId],
                errosAssinaturaDocumentosId: state.errosAssinaturaDocumentosId.filter(el => !action.payload.documentosId.includes(el)),
                assinandoAnexos: action.payload.incluiAnexos,
                errors: false
            };
        }

        case AssinaturaActions.GET_TOKEN_NEOID: {
            return {
                ...state,
                getTokenNeoId: true
            };
        }

        case AssinaturaActions.GET_TOKEN_NEOID_SUCCESS: {
            return {
                ...state,
                getTokenNeoId: false
            };
        }

        case AssinaturaActions.GET_TOKEN_NEOID_FAILED: {
            return {
                ...state,
                getTokenNeoId: false
            };
        }

        case AssinaturaActions.LIMPA_ASSINANDO_ANEXOS: {
            return {
                ...state,
                assinandoAnexos: false
            };
        }

        case AssinaturaActions.REMOVE_ASSINATURA_PADES: {
            return {
                ...state,
                removendoAssinaturaPadesId: [...state.removendoAssinaturaPadesId, action.payload]
            };
        }

        case AssinaturaActions.REMOVE_ASSINATURA_PADES_SUCCESS: {
            return {
                ...state,
                removendoAssinaturaPadesId: state.removendoAssinaturaPadesId.filter(id => id !== action.payload)
            };
        }

        case AssinaturaActions.REMOVE_ASSINATURA_PADES_FAILED: {
            return {
                ...state,
                removendoAssinaturaPadesId: state.removendoAssinaturaPadesId.filter(id => id !== action.payload)
            };
        }

        default:
            return state;
    }
};
