import AndX from '@cdk/components/criteria/operators/and-x';
import OrX from '@cdk/components/criteria/operators/or-x';

export enum ExpressionPositionEnum {
    LEFT = 'L',
    RIGHT = 'R',
    MIDDLE = 'M'
}

export type Aggregators
    = AndX
    | OrX;

export type aggregationExpression = 'andX'|'orX';
