import {NgModule} from '@angular/core';
import {MainFieldsProvider} from '@cdk/components/regra-etiqueta/providers/fields/main-fields.provider';
import {
    CriacaoProcessoFieldsProvider
} from '@cdk/components/regra-etiqueta/providers/fields/processo/criacao-processo-fields.provider';
import {
    DistribuicaoProcessoFieldsProvider
} from '@cdk/components/regra-etiqueta/providers/fields/processo/distribuicao-processo-fields.provider';
import {
    PrimeiraTarefaFieldsProvider
} from '@cdk/components/regra-etiqueta/providers/fields/processo/primeira-tarefa-fields.provider';
import {
    DistribuicaoTarefaFieldsProvider
} from '@cdk/components/regra-etiqueta/providers/fields/tarefa/distribuicao-tarefa-fields.provider';
import {AssuntoAdministrativoService} from '@cdk/services/assunto-administrativo.service';
import {ClassificacaoService} from '@cdk/services/classificacao.service';
import {EspecieProcessoService} from '@cdk/services/especie-processo.service';
import {EspecieRelevanciaService} from '@cdk/services/especie-relevancia.service';
import {EspecieTarefaService} from '@cdk/services/especie-tarefa.service';
import {LocalizadorService} from '@cdk/services/localizador.service';
import {ModalidadeInteressadoService} from '@cdk/services/modalidade-interessado.service';
import {ModalidadeMeioService} from '@cdk/services/modalidade-meio.service';
import {ModalidadeOrgaoCentralService} from '@cdk/services/modalidade-orgao-central.service';
import {PessoaService} from '@cdk/services/pessoa.service';
import {SetorService} from '@cdk/services/setor.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        // Providers
        MainFieldsProvider,
        DistribuicaoTarefaFieldsProvider,
        CriacaoProcessoFieldsProvider,
        DistribuicaoProcessoFieldsProvider,
        PrimeiraTarefaFieldsProvider,

        //Providers dependencies
        SetorService,
        AssuntoAdministrativoService,
        EspecieTarefaService,
        EspecieProcessoService,
        ClassificacaoService,
        ModalidadeMeioService,
        ModalidadeOrgaoCentralService,
        LocalizadorService,
        PessoaService,
        ModalidadeInteressadoService,
        EspecieRelevanciaService,
    ],
    exports: []
})
export class MainFieldsModule {
    constructor(
        _mainFieldsProvider: MainFieldsProvider,
        _distribuicaoTarefaFieldsProvider: DistribuicaoTarefaFieldsProvider,
        _criacaoProcessoFieldsProvider: CriacaoProcessoFieldsProvider,
        _distribuicaoProcessoFieldsProvider: DistribuicaoProcessoFieldsProvider,
        _primeiraTarefaFieldsProvider: PrimeiraTarefaFieldsProvider,
    ) {
        _mainFieldsProvider
            .addProvider(_distribuicaoTarefaFieldsProvider)
            .addProvider(_distribuicaoTarefaFieldsProvider)
            .addProvider(_criacaoProcessoFieldsProvider)
            .addProvider(_distribuicaoProcessoFieldsProvider)
            .addProvider(_primeiraTarefaFieldsProvider);
    }
}
