import ComparisonOperatorInterface from '@cdk/components/criteria/contracts/comparison-operator.interface';
import {CriteriaDataInterface} from '@cdk/components/criteria/contracts/criteria-data.interface';
import {AbstractOperator} from '@cdk/components/criteria/operators/abstract-operator';
import {
    ExpressionPositionEnum,
} from '@cdk/components/criteria/operators/index';

export abstract class AbstractComparisonOperator extends AbstractOperator implements ComparisonOperatorInterface
{
    constructor(
        protected readonly literalOperator: string,
        protected readonly expressionPosition: ExpressionPositionEnum = ExpressionPositionEnum.MIDDLE,
        protected readonly valueIsRequired: boolean = true,
        protected readonly multipleValueOperator: boolean = false
    ) {
        super();
    }

    /**
     * Returns criteria data.
     * @param field string
     * @param value string|number
     */
    public getCriteria(field: string, value: string|number): CriteriaDataInterface {
        let criteria: CriteriaDataInterface = {};
        criteria[field] = this.getParsedExpression(value);
        return criteria;
    }

    /**
     * Returns the parsed expression.
     *
     * @param value
     */
    public getParsedExpression(value: string|number): string {
        return this.expression.replace('arg', value as string)
    }

    /**
     * Returns literal operator.
     */
    public getLiteralOperator(): string {
        return this.literalOperator;
    }

    /**
     * Returns if form value is required.
     */
    public getValueIsRequired(): boolean {
        return this.valueIsRequired;
    }

    /**
     * Returns if operator is used for multiple values.
     */
    isMultipleValueOperator(): boolean {
        return this.multipleValueOperator;
    }

    /**
     * Check if the expression pattern correspond to the operator.
     * @param expression
     */
    public abstract checkExpression(expression: string): boolean;

    /**
     * Returns expression value.
     * @param expression
     */
    public abstract getValueFromExpression(expression: string): string;
}
