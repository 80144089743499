import {Injectable} from '@angular/core';
import {CriteriaFieldProviderInterface} from '@cdk/components/criteria/contracts/criteria-field-provider.interface';
import CriteriaFieldValueInterface from '@cdk/components/criteria/contracts/criteria-field-value.interface';
import {
    CriteriaLoadDataResultInterface,
    emptyCriteriaLoadDataResult
} from '@cdk/components/criteria/contracts/criteria-load-data-result.interface';
import CriteriaFieldModel from '@cdk/components/criteria/model/criteria-field.model';
import ComparisonOperatorInterface from '@cdk/components/criteria/contracts/comparison-operator.interface';
import Between from '@cdk/components/criteria/operators/between';
import Contains from '@cdk/components/criteria/operators/contains';
import EndsWith from '@cdk/components/criteria/operators/ends-with';
import Equal from '@cdk/components/criteria/operators/equal';
import GreaterThan from '@cdk/components/criteria/operators/greater-than';
import GreaterThanEqual from '@cdk/components/criteria/operators/greater-than-equal';
import IsFalse from '@cdk/components/criteria/operators/is-false';
import IsNotNull from '@cdk/components/criteria/operators/is-not-null';
import IsNull from '@cdk/components/criteria/operators/is-null';
import IsTrue from '@cdk/components/criteria/operators/is-true';
import LessThan from '@cdk/components/criteria/operators/less-than';
import LessThanEqual from '@cdk/components/criteria/operators/less-than-equal';
import NotContains from '@cdk/components/criteria/operators/not-contains';
import NotEndsWith from '@cdk/components/criteria/operators/not-ends-with';
import NotEqual from '@cdk/components/criteria/operators/not-equal';
import NotStartsWith from '@cdk/components/criteria/operators/not-starts-with';
import StartsWith from '@cdk/components/criteria/operators/starts-with';
import {
    AssuntoAdministrativo,
    Classificacao,
    EspecieProcesso, EspecieRelevancia,
    Localizador, ModalidadeInteressado,
    ModalidadeMeio,
    ModalidadeOrgaoCentral,
    MomentoDisparoRegraEtiqueta,
    Pagination, Pessoa,
    Setor,
} from '@cdk/models';
import {TitleCasePipe} from '@cdk/pipes/title-case.pipe';
import {AssuntoAdministrativoService} from '@cdk/services/assunto-administrativo.service';
import {ClassificacaoService} from '@cdk/services/classificacao.service';
import {EspecieProcessoService} from '@cdk/services/especie-processo.service';
import {EspecieRelevanciaService} from '@cdk/services/especie-relevancia.service';
import {LocalizadorService} from '@cdk/services/localizador.service';
import {ModalidadeInteressadoService} from '@cdk/services/modalidade-interessado.service';
import {ModalidadeMeioService} from '@cdk/services/modalidade-meio.service';
import {ModalidadeOrgaoCentralService} from '@cdk/services/modalidade-orgao-central.service';
import {PessoaService} from '@cdk/services/pessoa.service';
import {SetorService} from '@cdk/services/setor.service';
import {SiglaMomentoDisparoRegraEtiquetaJudicial} from 'modules/judicial/@cdk/components/regra-etiqueta/enums/sigla-momento-disparo-regra-etiqueta.enum';
import {ClasseNacional, FonteDados, OrgaoJulgador, Tribunal} from 'modules/judicial/@cdk/models';
import {ClasseNacionalService} from 'modules/judicial/@cdk/services/classe-nacional.service';
import {FonteDadosService} from 'modules/judicial/@cdk/services/fonte-dados.service';
import {OrgaoJulgadorService} from 'modules/judicial/@cdk/services/orgao-julgador.service';
import {TribunalService} from 'modules/judicial/@cdk/services/tribunal.service';
import {from, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class IntimacaoCriacaoFieldsProvider implements CriteriaFieldProviderInterface {

    constructor(
        private _setorService: SetorService,
        private _especieProcessoService: EspecieProcessoService,
        private _classificacaoService: ClassificacaoService,
        private _modalidadeMeioService: ModalidadeMeioService,
        private _modalidadeOrgaoCentralService: ModalidadeOrgaoCentralService,
        private _localizadorService: LocalizadorService,
        private _assuntoAdministrativoService: AssuntoAdministrativoService,
        private _pessoaService: PessoaService,
        private _modalidadeInteressadoService: ModalidadeInteressadoService,
        private _especieRelevanciaService: EspecieRelevanciaService,
        private _classeNacionalService: ClasseNacionalService,
        private _orgaoJulgadorService: OrgaoJulgadorService,
        private _tribunalService: TribunalService,
        private _fonteDadosService: FonteDadosService,
    ) {
    }

    supports(context: any): boolean {
        return context instanceof MomentoDisparoRegraEtiqueta
            && context.sigla === SiglaMomentoDisparoRegraEtiquetaJudicial.INTIMACAO_CRIACAO

    }

    getFields(context: any): CriteriaFieldModel[]|null {
        return [
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.documentoAvulsoOrigem',
                'Processo com Ofício de Origem',
                [
                    new IsNull('não'),
                    new IsNotNull('sim')
                ],
                (operator: ComparisonOperatorInterface) => of(null),
                (operator?: ComparisonOperatorInterface) => null,
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => ''
                }),
                10
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.semValorEconomico',
                'Processo Sem Valor Econômico',
                [
                    new IsTrue(),
                    new IsFalse()
                ],
                (operator: ComparisonOperatorInterface) => of(null),
                (operator?: ComparisonOperatorInterface) => null,
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => ''
                }),
                20
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.protocoloEletronico',
                'Processo com Protocolo Eletrônico',
                [
                    new IsTrue(),
                    new IsFalse()
                ],
                (operator: ComparisonOperatorInterface) => of(null),
                (operator?: ComparisonOperatorInterface) => null,
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => ''
                }),
                30
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.visibilidadeExterna',
                'Processo com Visibilidade Externa',
                [
                    new IsTrue(),
                    new IsFalse()
                ],
                (operator: ComparisonOperatorInterface) => of(null),
                (operator?: ComparisonOperatorInterface) => null,
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => ''
                }),
                40
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.valorEconomico',
                'Valor Econômico do Processo',
                [
                    new IsNotNull('tem'),
                    new IsNull('não tem'),
                    new Equal(),
                    new NotEqual(),
                    new LessThan(),
                    new LessThanEqual(),
                    new GreaterThan(),
                    new GreaterThanEqual(),
                    new Between(),
                ],
                (operator: ComparisonOperatorInterface) => {
                    switch (operator.getExpression()) {
                        case (new Between()).getExpression():
                            return from(import(
                                '@cdk/components/criteria/form-fields/cdk-range-valor-monetario-generico/cdk-range-valor-monetario-generico-form.module'
                                ).then(m => m.CdkRangeValorMonetarioGenericoFormModule))
                        default:
                            return from(import(
                                '@cdk/components/criteria/form-fields/cdk-valor-monetario-generico/cdk-valor-monetario-generico-form.module'
                                ).then(m => m.CdkValorMonetarioGenericoFormModule));
                    }
                },
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    switch (criteriaFieldValue.operator.getExpression()) {
                        case (new IsNull()).getExpression():
                        case (new IsNotNull()).getExpression():
                            return of<CriteriaLoadDataResultInterface>({
                                data: criteriaFieldValue.valor,
                                getValor: () => criteriaFieldValue.valor,
                                getExibicao: () => ''
                            });
                        default:
                            return of<CriteriaLoadDataResultInterface>({
                                data: criteriaFieldValue.valor,
                                getValor: () => criteriaFieldValue.valor,
                                getExibicao: () => criteriaFieldValue.valor
                            })
                    }
                },
                50
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.especieProcesso.id',
                'Processo de Trabalho do Processo',
                [
                    new Equal(),
                    new NotEqual(),
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-especie-processo/cdk-especie-processo-form.module'
                    ).then(m => m.CdkEspecieProcessoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    const especieProcessoPagination = new Pagination();
                    especieProcessoPagination.populate = ['populateAll'];
                    return {
                        especieProcessoPagination: especieProcessoPagination
                    }
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._especieProcessoService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: EspecieProcesso) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.nome
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                60
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.classificacao.id',
                'Classificação do Processo',
                [
                    new Equal(),
                    new NotEqual(),
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-classificacao/cdk-classificacao-form.module'
                    ).then(m => m.CdkClassificacaoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._classificacaoService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: Classificacao) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.nome
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                70
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.modalidadeMeio.id',
                'Meio do Processo',
                [
                    new Equal(),
                    new NotEqual(),
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-modalidade-meio/cdk-modalidade-meio-form.module'
                    ).then(m => m.CdkModalidadeMeioFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._modalidadeMeioService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: ModalidadeMeio) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.valor
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                80
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.titulo',
                'Título do Processo',
                [
                    new Contains(),
                    new NotContains(),
                    new StartsWith(),
                    new NotStartsWith(),
                    new EndsWith(),
                    new NotEndsWith(),
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-texto-generico/cdk-texto-generico-form.module'
                    ).then(m => m.CdkTextoGenericoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => criteriaFieldValue.valor
                }),
                90
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.descricao',
                'Descrição do Processo',
                [
                    new Contains(),
                    new NotContains(),
                    new StartsWith(),
                    new NotStartsWith(),
                    new EndsWith(),
                    new NotEndsWith(),
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-texto-generico/cdk-texto-generico-form.module'
                    ).then(m => m.CdkTextoGenericoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => criteriaFieldValue.valor
                }),
                100
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.setorAtual.unidade.modalidadeOrgaoCentral.id',
                'Órgão Central Atual do Processo',
                [
                    new Equal(),
                    new NotEqual(),
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-modalidade-orgao-central/cdk-modalidade-orgao-central-form.module'
                    ).then(m => m.CdkModalidadeOrgaoCentralFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._modalidadeOrgaoCentralService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: ModalidadeOrgaoCentral) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.valor
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                110
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.setorAtual.unidade.id',
                'Unidade Atual do Processo',
                [
                    new Equal(),
                    new NotEqual(),
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-unidade/cdk-unidade-form.module'
                    ).then(m => m.CdkUnidadeFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._setorService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: Setor) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.nome
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                120
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.setorAtual.id',
                'Setor Atual do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-setor/cdk-setor-form.module'
                    ).then(m => m.CdkSetorFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._setorService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: Setor) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => `${obj.nome} (${obj.unidade.sigla})`
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                130
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.localizador.id',
                'Localizador do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-localizador/cdk-localizador-form.module'
                    ).then(m => m.CdkLocalizadorFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._localizadorService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: Localizador) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.nome
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                140
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.assuntos.assuntoAdministrativo.id',
                'Assunto do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-assunto-administrativo/cdk-assunto-administrativo-form.module'
                    ).then(m => m.CdkAssuntoAdministrativoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._assuntoAdministrativoService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: AssuntoAdministrativo) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.nome
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                150
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.assuntos.principal',
                'Assunto Principal do Processo',
                [
                    new IsTrue(),
                    new IsFalse()
                ],
                (operator: ComparisonOperatorInterface) => of(null),
                (operator?: ComparisonOperatorInterface) => null,
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => ''
                }),
                160
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.interessados.pessoa.id',
                'Pessoa Interessada do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-pessoa/cdk-pessoa-form.module'
                    ).then(m => m.CdkPessoaFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._pessoaService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: Pessoa) => {
                                    let retorno = obj.nome;
                                    if (obj.numeroDocumentoPrincipal) {
                                        retorno += ' (' + obj.numeroDocumentoPrincipal + ')';
                                    }
                                    if (obj.pessoaValidada) {
                                        retorno += ' - VALIDADA';
                                    }
                                    if (obj.pessoaConveniada) {
                                        retorno += ' - CONVENIADA';
                                    }
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => TitleCasePipe.format(retorno)
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                170
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.interessados.modalidadeInteressado.id',
                'Modalidade do Interessado do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-modalidade-interessado/cdk-modalidade-interessado-form.module'
                    ).then(m => m.CdkModalidadeInteressadoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._modalidadeInteressadoService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: ModalidadeInteressado) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => obj.valor
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                180
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.interessados.pessoa.numeroDocumentoPrincipal',
                'Número do Documento Principal da Pessoa Interessada do Processo',
                [
                    new Contains(),
                    new NotContains(),
                    new StartsWith(),
                    new NotStartsWith(),
                    new EndsWith(),
                    new NotEndsWith(),
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-texto-generico/cdk-texto-generico-form.module'
                    ).then(m => m.CdkTextoGenericoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => criteriaFieldValue.valor
                }),
                190
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.relevancias.especieRelevancia.id',
                'Relevância do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-especie-relevancia/cdk-especie-relevancia-form.module'
                    ).then(m => m.CdkEspecieRelevanciaFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._especieRelevanciaService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: EspecieRelevancia) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.nome,
                                        getExibicao: () => obj.nome
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                200
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.relevancias.observacao',
                'Observação da Relevância do Processo',
                [
                    new Contains(),
                    new NotContains(),
                    new StartsWith(),
                    new NotStartsWith(),
                    new EndsWith(),
                    new NotEndsWith(),
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-texto-generico/cdk-texto-generico-form.module'
                    ).then(m => m.CdkTextoGenericoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => criteriaFieldValue.valor
                }),
                210
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processo.lembretes.conteudo',
                'Lembrete do Processo',
                [
                    new Contains(),
                    new NotContains(),
                    new StartsWith(),
                    new NotStartsWith(),
                    new EndsWith(),
                    new NotEndsWith(),
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-texto-generico/cdk-texto-generico-form.module'
                    ).then(m => m.CdkTextoGenericoFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => of<CriteriaLoadDataResultInterface>({
                    data: criteriaFieldValue.valor,
                    getValor: () => criteriaFieldValue.valor,
                    getExibicao: () => criteriaFieldValue.valor
                }),
                220
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.pessoaRepresentada.pessoa.id',
                'Pessoa Representada do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    '@cdk/components/criteria/form-fields/cdk-pessoa/cdk-pessoa-form.module'
                    ).then(m => m.CdkPessoaFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._pessoaService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: Pessoa) => {
                                    let retorno = obj.nome;
                                    if (obj.numeroDocumentoPrincipal) {
                                        retorno += ' (' + obj.numeroDocumentoPrincipal + ')';
                                    }
                                    if (obj.pessoaValidada) {
                                        retorno += ' - VALIDADA';
                                    }
                                    if (obj.pessoaConveniada) {
                                        retorno += ' - CONVENIADA';
                                    }
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => TitleCasePipe.format(retorno)
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                230
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processoJudicial.classeNacional.id',
                'Classe Nacional do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    'modules/judicial/@cdk/components/criteria/cdk-criteria-form/cdk-classe-nacional/cdk-classe-nacional-form.module'
                    ).then(m => m.CdkClasseNacionalFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._classeNacionalService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: ClasseNacional) => {
                                    let exibicao = obj.nome;
                                    if (obj?.codigoNacional) {
                                        exibicao += ' (' + obj.codigoNacional + ')';
                                    }
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => exibicao
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                240
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processoJudicial.orgaoJulgador.id',
                'Órgão Julgador do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    'modules/judicial/@cdk/components/criteria/cdk-criteria-form/cdk-orgao-julgador/cdk-orgao-julgador-form.module'
                    ).then(m => m.CdkOrgaoJulgadorFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._orgaoJulgadorService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: OrgaoJulgador) => {
                                    let exibicao = obj.nome;
                                    if (obj.tribunal?.sigla) {
                                        exibicao += ' (' + obj.tribunal?.sigla + ')';
                                    }
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => exibicao
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                250
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processoJudicial.orgaoJulgador.tribunal.id',
                'Tribunal do Órgão Julgador do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    'modules/judicial/@cdk/components/criteria/cdk-criteria-form/cdk-tribunal/cdk-tribunal-form.module'
                    ).then(m => m.CdkTribunalFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._tribunalService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: Tribunal) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => `${obj.nome} (${obj.id})`
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                260
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processoJudicial.valorCausa',
                'Valor da Causa do Processo',
                [
                    new IsNotNull('tem'),
                    new IsNull('não tem'),
                    new Equal(),
                    new NotEqual(),
                    new LessThan(),
                    new LessThanEqual(),
                    new GreaterThan(),
                    new GreaterThanEqual(),
                    new Between(),
                ],
                (operator: ComparisonOperatorInterface) => {
                    switch (operator.getExpression()) {
                        case (new Between()).getExpression():
                            return from(import(
                                '@cdk/components/criteria/form-fields/cdk-range-valor-monetario-generico/cdk-range-valor-monetario-generico-form.module'
                                ).then(m => m.CdkRangeValorMonetarioGenericoFormModule))
                        default:
                            return from(import(
                                '@cdk/components/criteria/form-fields/cdk-valor-monetario-generico/cdk-valor-monetario-generico-form.module'
                                ).then(m => m.CdkValorMonetarioGenericoFormModule));
                    }
                },
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    switch (criteriaFieldValue.operator.getExpression()) {
                        case (new IsNull()).getExpression():
                        case (new IsNotNull()).getExpression():
                            return of<CriteriaLoadDataResultInterface>({
                                data: criteriaFieldValue.valor,
                                getValor: () => criteriaFieldValue.valor,
                                getExibicao: () => ''
                            });
                        default:
                            return of<CriteriaLoadDataResultInterface>({
                                data: criteriaFieldValue.valor,
                                getValor: () => criteriaFieldValue.valor,
                                getExibicao: () => criteriaFieldValue.valor
                            })
                    }
                },
                270
            ),
            new CriteriaFieldModel(
                'vinculacaoProcessoJudicialProcesso.processoJudicial.fonteDados.id',
                'Fonte de Dados do Processo',
                [
                    new Equal(),
                    new NotEqual()
                ],
                (operator: ComparisonOperatorInterface) => from(import(
                    'modules/judicial/@cdk/components/criteria/cdk-criteria-form/cdk-fonte-dados/cdk-fonte-dados-form.module'
                    ).then(m => m.CdkFonteDadosFormModule)),
                (operator?: ComparisonOperatorInterface) => {
                    return {}
                },
                (criteriaFieldValue: CriteriaFieldValueInterface) => {
                    if (criteriaFieldValue.valor) {
                        return this._fonteDadosService
                            .get(parseInt(criteriaFieldValue.valor), JSON.stringify(['populateAll']))
                            .pipe(map(
                                (obj: FonteDados) => {
                                    return {
                                        data: obj,
                                        getValor: () => obj.id,
                                        getExibicao: () => `${obj.nome} (${obj.id})`
                                    }
                                }
                            ))
                    }
                    return of<CriteriaLoadDataResultInterface>(emptyCriteriaLoadDataResult());
                },
                280
            ),
        ];
    }

}
