import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LoginService} from '../../../app/main/auth/login/login.service';

@Injectable({
    providedIn: 'root'
})
export class SearchBarService {
    private _searchFieldName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _searchField: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _module: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private loginService: LoginService
    ) {
    }

    get searchFieldName(): Observable<string> {
        return this._searchFieldName.asObservable();
    }

    get searchField(): Observable<string> {
        return this._searchField.asObservable();
    }

    get module(): Observable<string> {
        return this._module.asObservable();
    }

    public setSearchFieldName(value: string): void {
        this._searchFieldName.next(value);

        const username = this.loginService.getUserProfile()?.username;

        let preferences = JSON.parse(localStorage.getItem('preferences'));
        if (preferences) {
            preferences = {
                ...preferences,
                sidebarSearchFieldNamePreference: value
            };
        } else {
            preferences = {username, sidebarSearchFieldNamePreference: value};
        }

        localStorage.setItem('preferences', JSON.stringify(preferences));
    }

    public setSearchField(value: string): void {
        this._searchField.next(value);

        const username = this.loginService.getUserProfile()?.username;

        let preferences = JSON.parse(localStorage.getItem('preferences'));
        if (preferences) {
            preferences = {
                ...preferences,
                sidebarSearchFieldPreference: value
            };
        } else {
            preferences = {username, sidebarSearchFieldPreference: value};
        }

        localStorage.setItem('preferences', JSON.stringify(preferences));
    }

    public setModule(value: string): void {
        this._module.next(value);
    }

    public getPreferences(): {
        username: string,
        sidebarSearchFieldNamePreference: string,
        sidebarSearchFieldPreference: string
    } | null {
        return localStorage.getItem('preferences') ? JSON.parse(localStorage.getItem('preferences')) : null;
    }

}
