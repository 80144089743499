import OperatorInterface from '@cdk/components/criteria/contracts/operator.interface';

export abstract class AbstractOperator implements OperatorInterface
{
    protected abstract readonly expression: string;
    protected abstract readonly literalOperator: string;

    /**
     * Returns expression.
     */
    public getExpression(): string {
        return this.expression
    }

    /**
     * Returns literalOperator
     */
    public getLiteralOperator(): string
    {
        return this.literalOperator;
    }
}
