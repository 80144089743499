import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {merge, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {CdkNavigationItem} from '@cdk/types';
import {cdkAnimations} from '@cdk/animations';
import {CdkNavigationService} from '@cdk/components/navigation/navigation.service';
import {LoginService} from '../../../../../app/main/auth/login/login.service';

@Component({
    selector: 'cdk-nav-vertical-collapsable',
    templateUrl: './collapsable.component.html',
    styleUrls: ['./collapsable.component.scss'],
    animations: cdkAnimations
})
export class CdkNavVerticalCollapsableComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    item: CdkNavigationItem;

    @HostBinding('class')
    classes = 'nav-collapsable nav-item';

    @HostBinding('class.open')
    public isOpen = false;

    @Input()
    allowGroupOfHiddenChildren: boolean = true;

    isGrantedRole: boolean;
    isCoordenador: boolean;

    originalChildren: CdkNavigationItem[];

    hiddenChildrenCollapsableGroup: CdkNavigationItem;
    hasHideableChildren: boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param _changeDetectorRef
     * @param _cdkNavigationService
     * @param _router
     * @param _loginService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _cdkNavigationService: CdkNavigationService,
        private _router: Router,
        public _loginService: LoginService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.hiddenChildrenCollapsableGroup = {
            id: 'outros-generos',
            title: 'Gêneros sem tarefas', // ALTERADO PELA PGE-RS
            type: 'collapsable',
            icon: 'exposure_zero', // ALTERADO PELA PGE-RS
            hideable: true,
            allowGroupOfHiddenChildren: false,
            badge: {
                title: '0',
                bg: '#F44336',
                fg: '#FFFFFF'
            },
            badgeCompartilhada: {
                id: 'compartilhadas_outros',
                badge: {
                    title: '0',
                    bg: '#fc6054',
                    fg: '#FFFFFF'
                }
            },
            children: [],
            canHide: (item: CdkNavigationItem): boolean => {
                return item.children.length === 0;
            },
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Listen for router events
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._unsubscribeAll)
        ).subscribe((event: NavigationEnd) => {
            // Check if the url can be found in
            // one of the children of this item
            if (this.isUrlInChildren(this.item, event.urlAfterRedirects) || this.item.startExpanded) {
                this.expand();
            } else {
                this.collapse();
            }
        });

        // Listen for collapsing of any navigation item
        this._cdkNavigationService.onItemCollapsed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (clickedItem) => {
                    if (clickedItem && clickedItem.children) {
                        // Check if the clicked item is one
                        // of the children of this item
                        if (this.isChildrenOf(this.item, clickedItem)) {
                            return;
                        }

                        // Check if the url can be found in
                        // one of the children of this item
                        if (this.isUrlInChildren(this.item, this._router.url)) {
                            return;
                        }

                        // If the clicked item is not this item, collapse...
                        if (this.item !== clickedItem && !this.item.startExpanded) {
                            this.collapse();
                        }
                    }
                }
            );

        // Check if the url can be found in
        // one of the children of this item
        if (this.isUrlInChildren(this.item, this._router.url) || this.item.startExpanded) {
            this.expand();
        } else {
            this.collapse();
        }

        // Subscribe to navigation item
        merge(
            this._cdkNavigationService.onNavigationItemAdded,
            this._cdkNavigationService.onNavigationItemUpdated,
            this._cdkNavigationService.onNavigationItemRemoved
        ).pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe(() => {
            const badge = this.item.badge;

            if (badge) {
                delete this.item['badge'];
                this._changeDetectorRef.detectChanges();
                this.item['badge'] = badge;
            }

            const badgeShares = this.item.badgeCompartilhada;

            if (badgeShares) {
                delete this.item['badgeCompartilhada'];
                this._changeDetectorRef.detectChanges();
                this.item['badgeCompartilhada'] = badgeShares;
            }

            if (this.hasHideableChildren && this.item.allowGroupOfHiddenChildren) {
                this.setupHiddenChildren();

                if (this.item.children.findIndex(element => element.id === 'outros-generos') === -1) {
                    this.item.children.push(this.hiddenChildrenCollapsableGroup);
                }
            }

            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        this.isGrantedRole = true;

        if (this.item.role) {
            this.isGrantedRole = false;
            if (Array.isArray(this.item.role)) {
                this.item.role.forEach((role) => {
                    if (!this.isGrantedRole) {
                        this.isGrantedRole = this._loginService.isGranted(role);
                    }
                });
            } else {
                this.isGrantedRole = this._loginService.isGranted(this.item.role);
            }
        }

        this.isCoordenador = this._loginService.isCoordenador();
        this.setupHiddenChildren();
    }

    ngAfterViewInit(): void {
        if (!this.originalChildren) {
            this.originalChildren = [...this.item.children];
        }
        if (this.item?.children?.filter(children => children.hideable)?.length) {
            this.hasHideableChildren = true;

            this.setupHiddenChildren();

            if (this.item.children.findIndex(element => element.id === 'outros-generos') === -1) {
                this.item.children.push(this.hiddenChildrenCollapsableGroup);
            }
            this._changeDetectorRef.markForCheck();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(true);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setup hidden children
     */
    setupHiddenChildren(): void {
        // Check if the collapsable has hideable children
        if (this.hasHideableChildren) {
            const newChildren = [];
            const children = this.item.children?.filter(children => children.hideable);
            children.forEach(child => {
                if (child.type !== 'collapsable') {
                    if (child.canHide(child)) {
                        const reversedChild = {
                            ...child
                        };
                        reversedChild.canHide = (item: CdkNavigationItem): boolean => {
                            let countBadges = 0;
                            countBadges = parseInt(item.badge?.title, 10);
                            if (item.badgeCompartilhada) {
                                countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                            }
                            return countBadges > 0;
                        };
                        newChildren.push(reversedChild);
                    }
                }
                if (child.type === 'collapsable' && child.id !== 'outros-generos') {
                    child.children.filter(children => children.hideable && children.canHide(children)).forEach(child => {
                        const reversedChild = {
                            ...child
                        };
                        reversedChild.canHide = (item: CdkNavigationItem): boolean => {
                            let countBadges = 0;
                            countBadges = parseInt(item.badge?.title, 10);
                            if (item.badgeCompartilhada) {
                                countBadges += parseInt(item.badgeCompartilhada.badge?.title, 10);
                            }
                            return countBadges > 0;
                        };
                        newChildren.push(reversedChild);
                    });
                }
            });
            this.hiddenChildrenCollapsableGroup.children = [...newChildren];
        }
    }

    /**
     * Toggle collapse
     *
     * @param ev
     */
    toggleOpen(ev): void {
        ev.preventDefault();

        this.isOpen = !this.isOpen;

        // Navigation collapse toggled...
        this._cdkNavigationService.onItemCollapsed.next(this.item);
        this._cdkNavigationService.onItemCollapseToggled.next(true);
    }

    /**
     * Expand the collapsable navigation
     */
    expand(): void {
        if (this.isOpen) {
            return;
        }

        this.isOpen = true;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        this._cdkNavigationService.onItemCollapseToggled.next(true);
    }

    /**
     * Collapse the collapsable navigation
     */
    collapse(): void {
        if (!this.isOpen) {
            return;
        }

        this.isOpen = false;

        // Mark for check
        this._changeDetectorRef.markForCheck();

        this._cdkNavigationService.onItemCollapseToggled.next(true);
    }

    /**
     * Check if the given parent has the
     * given item in one of its children
     *
     * @param parent
     * @param item
     * @returns
     */
    isChildrenOf(parent, item): boolean {
        const children = parent.children;

        if (!children) {
            return false;
        }

        if (children.indexOf(item) > -1) {
            return true;
        }

        for (const child of children) {
            if (child.children) {
                if (this.isChildrenOf(child, item)) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Check if the given url can be found
     * in one of the given parent's children
     *
     * @param parent
     * @param url
     * @returns
     */
    isUrlInChildren(parent, url): boolean {
        const children = parent?.children;

        if (!children) {
            return false;
        }

        for (const child of children) {
            if (child.children) {
                if (this.isUrlInChildren(child, url) && (!child.hideable || !child.canHide(child))) {
                    return true;
                }
            }

            if ((child.url === url || url.includes(child.url)) && (!child.hideable || !child.canHide(child))) {
                return true;
            }
        }

        return false;
    }

}
